.nav_bg {
    /* background-color: rgba(255, 255, 255, 0.60) !important; */
    backdrop-filter: blur(24px);
    z-index: 9999;
    position: fixed;
    left: 0;
    right: 0;
    border-bottom: 0.5px solid #4A686A14;
}


/* .header_logo{
    margin-right: 0px;
} */


/* .get_start_btn {
    padding: 1rem 1.5rem;
    border: 1px solid #FAD419;
    background: #FAD419;
    font-size: 16px;
    color: #1B1B1E;
    font-weight: 500;
    border-radius: 4px;
    line-height: 24px;
    font-family: 'Inter';
} */


/* .get_start_btn:hover {
    background: #F2BD00;
    box-shadow: 0px 3px 8px rgb(25 28 26 / 24%);
    color: #1B1B1E;
} */

.but_nav_start {
    margin-left: 80px;
    display: flex;
}

.hamburger-react {
    z-index: 99999;
}

.logoCenterText2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #4D45D4 !important;
    padding-right: 8px;
    width: 30%;
}

.logoCenterText1 {
    color: #0C3D6F;
}

.logoCenterText1:hover {
    color: #0C3D6F;
}

.nav_parent {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Hamburger_boder {
    border: none;
}

.offcanvas-body {
    width: 100%;
    padding-top: 0px
}

.ulstylink {
    /* margin-right: 60px; */
    align-items: center;
}

.ulstylink .nav-link {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    padding: 4px 5px;
}

.navbar-nav .nav-link {
    padding: 4px 20px;
}

.navbar-nav .nav-link:hover {
    color: #1b1b1e;
}

.modal-dialog {
    margin-top: 90px;
}

.nav_padding {
    padding-left: 20px;
    padding-right: 20px;
}

@media only screen and (min-width: 1440px) {
    /* .logoCenterText2 {
        display: flex;
    }
    .nav_padding {
    padding-left: 20px;
    padding-right: 20px;
} */
}

@media only screen and (max-width: 1200px) {
    .logoCenterText2 {
        font-size: 24px;
        display: flex;
        width: 30%;
    }
    .nav_padding {
        padding-left: 20px;
        padding-right: 20px;
    }
    .navbar-brand {
        padding-top: 5.5px;
        padding-bottom: 5.5px;
    }
}

@media only screen and (max-width: 991px) {
    /* .container{
    --bs-gutter-x: 0;
    width: 100%;
} */
    .offcanvas.offcanvas-end {
        width: 100%;
        top: 85px;
    }
    .offcanvas-backdrop {
        top: 85px;
    }
    .offcanvas {
        background-clip: border-box;
    }
    .ulstylink {
        align-items: start;
        width: 100%;
        box-shadow: 0px -1px 0px rgb(50 50 50 / 10%) !important;
    }
    .but_nav_start {
        display: flex;
        justify-content: center;
        padding-top: 20px;
        margin-left: 0px;
    }
    .NavLinkStyle {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        color: #191C1Acc !important;
        padding-top: 126px;
    }
    .nav-link {
        padding: 8px 20px;
        margin: 5px 0px;
        background: #FFFFFF;
        border-radius: 6px;
        display: flex;
        justify-content: start;
    }
    .ulstylink .nav-link {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        width: 100%;
    }
}

.navbar-expand-lg {
    padding-right: 20px;
    padding-left: 20px;
}

.nav_padding {
    padding-left: 20px;
    padding-right: 20px;
}

@media only screen and (max-width: 768px) {
    .navbar-expand-lg {
        padding: 4px 0px;
    }
    .imgStyFooterlogo {
        width: 25%;
        margin-right: 8px;
    }
    .ulstylink {
        box-shadow: 0px -1px 0px rgb(50 50 50 / 10%) !important;
    }
    .nav-link {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding-top: 32px;
        padding: 8px 20px !important;
    }
    .nav_padding {
        padding-left: 20px;
        padding-right: 20px;
    }
    .NavLinkStyle {
        padding-top: 126px;
    }
    .navbar-brand {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .Hamburger_boder {
        border: none;
        padding: 0px;
    }
}

@media only screen and (max-width: 500px) {
    .navbar-expand-lg {
        padding: 4px 0px;
    }
    .imgStyFooterlogo {
        width: 40%;
        margin-right: 8px;
    }
    .navbar-nav {
        padding: 4px 4px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding-top: 32px;
    }
    .nav_padding {
        padding-left: 20px;
        padding-right: 20px;
    }
    .NavLinkStyle {
        padding-top: 126px;
    }
    .Hamburger_boder {
        border: none;
        padding: 0px;
    }
    .offcanvas.offcanvas-end {
        width: 100%;
        top: 56px;
    }
    .offcanvas-backdrop {
        top: 55px;
    }
}

@media only screen and (max-width: 425px) {
    .imgStyFooterlogo {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }
    .logoCenterText2 {
        font-size: 20px;
    }
    .navbar-nav {
        padding: 4px 20px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding-top: 32px;
    }
    .nav_padding {
        padding-left: 20px;
        padding-right: 20px;
    }
    .NavLinkStyle {
        padding-top: 126px;
    }
    .Hamburger_boder {
        border: none;
        padding: 0px;
    }
    .logoimg {
        width: 200px;
    }
}

@media only screen and (max-width: 384px) {
    .nav-link {
        padding: 8px 20px !important;
        font-size: 18px !important;
        line-height: 6px;
    }
    .NavLinkStyle {
        padding-top: 126px;
    }
    .offcanvas.offcanvas-end {
        width: 100%;
        top: 55px;
    }
}

@media only screen and (max-width: 375px) {
    .logoCenterText2 {
        font-size: 18px;
    }
    .navbar-nav {
        padding: 15px 20px;
        margin: 5px 0px;
        font-size: 16px;
        line-height: 6px;
    }
    .NavLinkStyle {
        padding-top: 60px;
    }
}

@media only screen and (max-width: 320px) {
    .logoCenterText2 {
        font-size: 18px !important;
    }
}