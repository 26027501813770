body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav_bg {
    /* background-color: rgba(255, 255, 255, 0.60) !important; */
    -webkit-backdrop-filter: blur(24px);
            backdrop-filter: blur(24px);
    z-index: 9999;
    position: fixed;
    left: 0;
    right: 0;
    border-bottom: 0.5px solid #4A686A14;
}


/* .header_logo{
    margin-right: 0px;
} */


/* .get_start_btn {
    padding: 1rem 1.5rem;
    border: 1px solid #FAD419;
    background: #FAD419;
    font-size: 16px;
    color: #1B1B1E;
    font-weight: 500;
    border-radius: 4px;
    line-height: 24px;
    font-family: 'Inter';
} */


/* .get_start_btn:hover {
    background: #F2BD00;
    box-shadow: 0px 3px 8px rgb(25 28 26 / 24%);
    color: #1B1B1E;
} */

.but_nav_start {
    margin-left: 80px;
    display: flex;
}

.hamburger-react {
    z-index: 99999;
}

.logoCenterText2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #4D45D4 !important;
    padding-right: 8px;
    width: 30%;
}

.logoCenterText1 {
    color: #0C3D6F;
}

.logoCenterText1:hover {
    color: #0C3D6F;
}

.nav_parent {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Hamburger_boder {
    border: none;
}

.offcanvas-body {
    width: 100%;
    padding-top: 0px
}

.ulstylink {
    /* margin-right: 60px; */
    align-items: center;
}

.ulstylink .nav-link {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    padding: 4px 5px;
}

.navbar-nav .nav-link {
    padding: 4px 20px;
}

.navbar-nav .nav-link:hover {
    color: #1b1b1e;
}

.modal-dialog {
    margin-top: 90px;
}

.nav_padding {
    padding-left: 20px;
    padding-right: 20px;
}

@media only screen and (min-width: 1440px) {
    /* .logoCenterText2 {
        display: flex;
    }
    .nav_padding {
    padding-left: 20px;
    padding-right: 20px;
} */
}

@media only screen and (max-width: 1200px) {
    .logoCenterText2 {
        font-size: 24px;
        display: flex;
        width: 30%;
    }
    .nav_padding {
        padding-left: 20px;
        padding-right: 20px;
    }
    .navbar-brand {
        padding-top: 5.5px;
        padding-bottom: 5.5px;
    }
}

@media only screen and (max-width: 991px) {
    /* .container{
    --bs-gutter-x: 0;
    width: 100%;
} */
    .offcanvas.offcanvas-end {
        width: 100%;
        top: 85px;
    }
    .offcanvas-backdrop {
        top: 85px;
    }
    .offcanvas {
        background-clip: border-box;
    }
    .ulstylink {
        align-items: start;
        width: 100%;
        box-shadow: 0px -1px 0px rgb(50 50 50 / 10%) !important;
    }
    .but_nav_start {
        display: flex;
        justify-content: center;
        padding-top: 20px;
        margin-left: 0px;
    }
    .NavLinkStyle {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        color: #191C1Acc !important;
        padding-top: 126px;
    }
    .nav-link {
        padding: 8px 20px;
        margin: 5px 0px;
        background: #FFFFFF;
        border-radius: 6px;
        display: flex;
        justify-content: start;
    }
    .ulstylink .nav-link {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        width: 100%;
    }
}

.navbar-expand-lg {
    padding-right: 20px;
    padding-left: 20px;
}

.nav_padding {
    padding-left: 20px;
    padding-right: 20px;
}

@media only screen and (max-width: 768px) {
    .navbar-expand-lg {
        padding: 4px 0px;
    }
    .imgStyFooterlogo {
        width: 25%;
        margin-right: 8px;
    }
    .ulstylink {
        box-shadow: 0px -1px 0px rgb(50 50 50 / 10%) !important;
    }
    .nav-link {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding-top: 32px;
        padding: 8px 20px !important;
    }
    .nav_padding {
        padding-left: 20px;
        padding-right: 20px;
    }
    .NavLinkStyle {
        padding-top: 126px;
    }
    .navbar-brand {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .Hamburger_boder {
        border: none;
        padding: 0px;
    }
}

@media only screen and (max-width: 500px) {
    .navbar-expand-lg {
        padding: 4px 0px;
    }
    .imgStyFooterlogo {
        width: 40%;
        margin-right: 8px;
    }
    .navbar-nav {
        padding: 4px 4px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding-top: 32px;
    }
    .nav_padding {
        padding-left: 20px;
        padding-right: 20px;
    }
    .NavLinkStyle {
        padding-top: 126px;
    }
    .Hamburger_boder {
        border: none;
        padding: 0px;
    }
    .offcanvas.offcanvas-end {
        width: 100%;
        top: 56px;
    }
    .offcanvas-backdrop {
        top: 55px;
    }
}

@media only screen and (max-width: 425px) {
    .imgStyFooterlogo {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }
    .logoCenterText2 {
        font-size: 20px;
    }
    .navbar-nav {
        padding: 4px 20px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding-top: 32px;
    }
    .nav_padding {
        padding-left: 20px;
        padding-right: 20px;
    }
    .NavLinkStyle {
        padding-top: 126px;
    }
    .Hamburger_boder {
        border: none;
        padding: 0px;
    }
    .logoimg {
        width: 200px;
    }
}

@media only screen and (max-width: 384px) {
    .nav-link {
        padding: 8px 20px !important;
        font-size: 18px !important;
        line-height: 6px;
    }
    .NavLinkStyle {
        padding-top: 126px;
    }
    .offcanvas.offcanvas-end {
        width: 100%;
        top: 55px;
    }
}

@media only screen and (max-width: 375px) {
    .logoCenterText2 {
        font-size: 18px;
    }
    .navbar-nav {
        padding: 15px 20px;
        margin: 5px 0px;
        font-size: 16px;
        line-height: 6px;
    }
    .NavLinkStyle {
        padding-top: 60px;
    }
}

@media only screen and (max-width: 320px) {
    .logoCenterText2 {
        font-size: 18px !important;
    }
}
.parent_container .container {
    padding-right: 20px !important;
    padding-right: var(--bs-gutter-x, 20px) !important;
    padding-left: 20px !important;
    padding-left: var(--bs-gutter-x, 20px) !important;
}

.parent_container .second_block_parent_margin {
    padding-top: 0px;
    background: white;
}


/* -----------------banner 1------------------- */

.homeimg1 {
    display: block;
}

.homeimg2 {
    display: none;
}

.addPaddingBlockFrist {
    padding-top: 40px;
}

.firstBlock_Text {
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
    color: #26331fe0;
    margin-top: 80px;
}

.BannerImgFirstBlock {
    text-align: end;
    margin-top: 4rem;
}

.AmountColor {
    color: #85BB65;
}

.firstBlock_para_text {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    color: #191C1ACC;
    padding-bottom: 30px;
    padding-top: 20px;
    max-width: 600px;
    margin-bottom: 0px;
}

.firstBlock_Text_parent input:focus {
    outline: none !important;
    border-color: #85BB65;
    border-radius: 6px;
}

.firstBlock_Text_parent ::-webkit-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #191C1AE0;
    /* Firefox */
}

.firstBlock_Text_parent ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #191C1AE0;
    /* Firefox */
}

.firstBlock_Text_parent :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #191C1AE0;
}

.firstBlock_Text_parent ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #191C1AE0;
}

.res_width {
    text-align: center;
}

.AddPadingFirstBlockLink {
    padding-top: 30px;
    padding-bottom: 12px;
    max-width: 374px;
    text-align: center;
    margin: 0px auto;
}

.req_text {
    color: #465364;
}

.millin_text {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #46536499;
    text-align: center;
    padding-top: 24px;
    padding-bottom: 64px;
}

.million {
    color: #85BB65;
    font-weight: 700;
}

.firstBlock_subpara_text {
    font-size: 12px;
    font-weight: 500;
    margin-right: 17rem;
    color: #46536499;
    text-align: center;
    margin: auto;
    line-height: 14px;
}

.firstBlock_subpara_text a {
    /* color: #85BB65 !important; */
    font-weight: 700 !important;
    cursor: pointer;
}

.formalign_row {
    padding: 0px 58px;
}

@media only screen and (max-width: 991px) {
    .addPaddingBlockFrist {
        padding-top: 60px;
        text-align: center;
        margin: 0px auto;
    }
    .firstBlock_Text {
        font-size: 48px;
        text-align: center;
        line-height: 58px;
        font-weight: 700;
        max-width: none;
        margin-top: 75px;
    }
    .firstBlock_para_text {
        font-size: 20px;
        line-height: 24px;
        margin-right: 0rem;
        max-width: 600px;
        margin: 0px auto;
        padding-bottom: 40px;
        font-weight: 400;
        text-align: center;
    }
    .firstBlock_subpara_text {
        padding-top: 1rem;
        font-weight: 600;
        font-size: 12px;
        max-width: 374px;
    }
    .AddPadingFirstBlockLink {
        padding-top: 30px;
        padding-bottom: 12px;
    }
    .BoxParentStyle {
        padding-top: 0px;
        padding-bottom: 10px;
    }
    .GetstartButtonContainer {
        padding-top: 20px;
        padding-bottom: 0px;
    }
    .BannerImgFirstBlock {
        text-align: center;
        margin-top: 0rem;
    }
    .imgBannetSecondBlock {
        text-align: center;
    }
    .millin_text {
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
    .BoxHeadding {
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
        color: #26331fe0;
        padding-top: 28px;
    }
}

@media only screen and (max-width: 767px) {
    .formalign_row {
        padding: 0px 12px;
    }
    .accordion-button::after {
        width: 1rem;
        height: 1rem;
    }
    .res_width {
        width: 100%;
    }
    .ArrowIconParent {
        padding: 16px 32px;
        height: 56px;
        display: flex;
        align-items: center;
    }
    .BannerImgFirstBlock {
        padding-top: 0px;
    }
    /* .homeimg1 {
        display: none;
    } */
    .homeimg2 {
        display: block;
    }
    .form-inline {
        padding-top: 55px;
        text-align: center;
    }
    .AddPadingCircleArrow {
        padding: 0px 0px 0px 0px !important;
    }
    /* nav  */
    .Get_btn_Mobile {
        background: #ffffffe0;
        padding: 20px 40px;
        border: 1px solid #85BB65;
        background: #85BB65;
        font-size: 16px;
        color: #ffffff;
        font-weight: 500;
        border-radius: 6px;
        line-height: 24px;
    }
    .imgArrowDisplydesk {
        display: none;
    }
    .ResponsivFristBlock.addPaddingBlockFrist {
        padding-top: 0px;
    }
    .addPaddingBlockFrist .second_block_parent_margin .container {
        padding-right: .0rem !important;
        padding-right: var(--bs-gutter-x, .0rem) !important;
        padding-left: .0rem !important;
        padding-left: var(--bs-gutter-x, .0rem) !important;
    }
    .firstBlock_Text {
        font-size: 32px;
        font-weight: 700;
        max-width: 374px;
        margin: 32px auto 0px;
        line-height: 40px;
    }
    .firstBlock_para_text {
        color: #191C1ACC;
        padding-top: 12px;
        padding-bottom: 30px;
        max-width: 344px;
        margin: 0px auto;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
    }
    .ArrowIconParentbanner {
        border: 1px solid #85BB65;
        background: #85BB65;
        border-radius: 6px;
        font-size: 16px;
        width: 92.5%;
        line-height: 19px;
        /* box-shadow: 0px 3px 8px rgb(25 28 26 / 24%); */
        padding: 16.5px 32px;
        align-items: center;
    }
    .AddPadingFirstBlockLink {
        padding-top: 8px;
        padding-bottom: 16px;
    }
    .millin_text {
        font-size: 14px;
        line-height: 17px;
        padding-top: 16px;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
}

@media only screen and (max-width: 384px) {
    .firstBlock_Text {
        font-size: 32px;
        font-weight: 700;
        max-width: 90%;
    }
    .firstBlock_para_text {
        max-width: 90%;
        margin: 0px auto;
    }
    .firstBlock_subpara_text {
        padding-top: 1rem;
        font-weight: 600;
        font-size: 12px;
        max-width: 90%;
    }
    .ArrowIconParentbanner {
        width: 100%;
    }
}

@media only screen and (max-width: 320px) {
    .AddPadingCircleArrow {
        padding: 0px 0px 0px 0px !important;
    }
}


/* Header and first---------------- */


/* .navbar-nav .form-inline {
    margin-left: 60px;
}
.homeimg1 {
    display: block;
}

.homeimg2 {
    display: none;
}

.active {
    color: #1b1b1e !important;
}

.get_start_btn {
    padding: 1rem 1.5rem;
    border: 1px solid #FAD419;
    background: #FAD419;
    font-size: 16px;
    color: #1B1B1E;
    font-weight: 500;
    border-radius: 4px;
    line-height: 24px;
    font-family: 'Inter';
}

.get_start_btn:hover {
    background: #F2BD00;
    box-shadow: 0px 3px 8px rgb(25 28 26 / 24%);
    color: #1B1B1E;
}

.addPaddingBlockFrist {
    padding-top: 40px;
}

.amount_but:hover {
    border: 0.5px solid #4D45D4;
}

.firstBlock_Text {
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
    color: #1b1b1e;
}

.BannerImgFirstBlock {
    text-align: end;
}

.AmountColor {
    color: #4D45D4;
}

.firstBlock_para_text {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #1b1b1eCC;
    padding-top: 20px;
    margin-right: 13rem;
    margin-bottom: 0px;
}

.amount_but {
    padding: 12px 20px;
    width: 100%;
    background: #FFFFFF;
    border: 0.5px solid #1b1b1e33;
    border-radius: 8px;
    font-size: 16px;
    color: #1b1b1e;
    font-weight: 500;
    line-height: 29px;
}

.firstBlock_Text_parent input:focus {
    outline: none !important;
    border-color: #4D45D4;
    border-radius: 4px;
}

.firstBlock_Text_parent ::placeholder {

    color: #1b1b1eE0;

}

.firstBlock_Text_parent :-ms-input-placeholder {

    color: #1b1b1eE0;
}

.firstBlock_Text_parent ::-ms-input-placeholder {

    color: #1b1b1eE0;
}

.PaddingAmountbtn {
    padding-top: 40px;
}

.AddPadingFirstBlockLink {
    padding-top: 12px;
    padding-bottom: 16px;
}

.AddPadingCircleArrow {
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
}

.ArrowIconParent {
    border: 1px solid #FAD419;
    background: #FAD419;
    border-radius: 4px;
    padding: 18px 32px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.AddPadingCircleArrow a {
    font-size: 16px;
    letter-spacing: 1px;
    font-family: 'Inter';
    font-weight: 500 !important;
    line-height: 18px;
    color: #1B1B1E !important;
}

.ArrowIconParent:hover {
    color: #ffffff;
    background: #F2BD00;
    box-shadow: 0px 3px 8px rgb(25 28 26 / 24%);
}


.millin_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #46536499;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 16px;
}

.million {
    color: #4D45D4;
    font-weight: 700;
}

.firstBlock_subpara_text {
    font-size: 12px;
    font-weight: 500;
    margin-right: 17rem;
    color: #46536499;
    text-align: center;
    margin: auto;
    line-height: 19px;
}

.firstBlock_subpara_text a {
    color: #4D45D4 !important;
    font-weight: 700 !important;
    cursor: pointer;
}

@media only screen and (max-width: 1440px) {


    .imgArrowDisplymob {
        display: none;
    }

    .imgArrowDisplydesk {
        display: block;
    }
}

@media only screen and (max-width: 1200px) {
    .ArrowIconParent:hover .icon {
        fill: #fff;
    }
}

@media only screen and (max-width: 1024px) {

    .ArrowIconParent:hover .icon {
        fill: #fff;
    }

    .firstBlock_Text {
        font-size: 2rem;
    }
}

@media only screen and (min-width: 2560px) {


    .amount_but {
        padding: 18px 20px;
    }
}
@media only screen and (min-width: 1440px) and (max-width: 2560px) {
   
    .imgArrowDisplymob {
        display: none;
    }
}

@media only screen and (min-width: 1440px) {
   
}

@media only screen and (max-width: 1440px) {
  

    .logoDisplay1 {
        display: block;
    }

    .logoDisplay2 {
        display: block;
    }
}



@media only screen and (max-width: 1024px) {
    .amount_but {
        font-size: 20px;
    }

    .logoCenterText2_footer {
        font-size: 24px;
    }

    .logoDisplay1 {
        display: none;
    }

    .logoDisplay2 {
        display: block;
        padding: 0px 0px 0px;
    }

    .get_start_btn {
        padding: 20px 40px;
        border: 1px solid #FAD419;
        background: #FAD419;
        font-size: 16px;
        color: #1B1B1E;
        font-weight: 500;
        border-radius: 4px;
        line-height: 24px;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 10px;
        padding-left: 10px;
    }
}

@media only screen and (max-width: 810px) {
  
    .get_start_btn {
        padding: 16px 40px;
        background: #ffffff;
        border: 2px solid #191C1A;
        font-size: 20px;
        color: #191C1A;
        border-radius: 4px;
        line-height: 24px;
    }

    .form-inline {
        padding-left: 2rem;
        margin: auto;
        padding-top: 20px;
        padding-bottom: 60px;
    }

}

@media only screen and (max-width: 991px) {
    .firstBlock_para_text {
        font-size: 16px;
        margin-right: 0rem;
        text-align: start;
        margin-right: 12rem;
    }

    .firstBlock_Text {
        text-align: start;
        line-height: 39px;
    }

    .ulstylink {
        box-shadow: 0px -1px 0px rgb(50 50 50 / 10%) !important;
    }

    .navbar-nav {
        width: 100%;
        padding-top: 40px;
        background: #f9fffb;
    }

    .logoDisplay1 {
        display: none;
    }

    .logoDisplay2 {
        display: block;
        padding: 0px 0px 0px;
    }

   

    .get_start_btn {
        padding: 16px 40px;
        background: #ffffff;
        border: 1px solid #4D45D4;
        background: #4D45D4;
        ;
        font-size: 20px;
        color: #ffffff;
        border-radius: 4px;
        line-height: 24px;
    }

    .form-inline {
        padding-top: 20px;
    }

 

    .form-inline {
        padding-top: 55px;
        text-align: center;
        margin: auto;
        height: 100vh;
    }

    /* Effect css  */


/* .navbar .button-wrap {
        text-align: center;
    }

    .navbar .button {
        position: relative;
        display: inline-block;
        margin: 1em;
        padding: 0;
        border: 0;
        background: none;
        color: #286aab;
        font-size: 1.2em;
        font-family: "arial black";
        transition: color 0.7s;
        cursor: pointer;
    }

    .navbar .button.button-click,
    .navbar .button:focus {
        outline: none;
        color: #3c8ddc;
    }

    .navbar .button:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -25px 0 0 -25px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        opacity: 0;
    }

    effect b---------------
    .navbar .button-effect-b:after {
        width: 50px;
        height: 50px;
        border: 10px solid rgba(111, 148, 182, 0.1);
    }

    .navbar .button-effect-b.button-click:after {
        -webkit-animation: anim-effect-b 0.3s forwards;
    }

    @-webkit-keyframes anim-effect-b {
        0% {
            -webkit-transform: scale3d(0.3, 0.3, 1);
        }

        25%,
        50% {
            opacity: 1;
        }

        100% {
            opacity: 0;
            -webkit-transform: scale3d(1.2, 1.2, 1);
        }
    } 

    Nav Link Effect-----------
    .ulstylink .nav-link {
        padding: 0px 0px;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding: 8px 20px;
        margin: 5px 0px;
        background: #FFFFFF;
        border-radius: 8px;
    }
}

@media only screen and (max-width: 768px) and (min-width:424px) {
    .firstBlock_Text {
        text-align: center;
        line-height: 39px;
    }
    .firstBlock_para_text {
        font-size: 16px;
        margin-right: 0rem;
        text-align: center;
    }
}
@media only screen and (max-width: 768px) {
    .imgStyFooterlogo {
        width: 25%;
        margin-right: 8px;
    }

    .firstBlock_Text {
        text-align: center;
        line-height: 39px;
    }

    /* .ulstylink {
        box-shadow: 0px -1px 0px rgb(50 50 50 / 10%) !important;
    } */


/* .navbar-nav {
        width: 100%;
        padding-top: 40px;
    } 
    .logoDisplay1 {
        display: none;
    }

    .logoDisplay2 {
        display: block;
        padding: 0px 0px 0px;
    }

    /* .navbarMargin {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .NavLinkStyle li a {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #191C1A !important;
        padding-top: 32px;
    } 

    .get_start_btn {
        padding: 16px 40px;
        border: 1px solid #4D45D4;
        background: #4D45D4;
        ;
        font-size: 20px;
        color: #ffffff;
        border-radius: 4px;
        line-height: 24px;
    }

    .form-inline {
        padding-top: 20px;
    }
 .ulstylink li {
        padding-left: 0rem;
    } 

    .form-inline {
        padding-top: 55px;
        text-align: center;
    }

    /* .nav_padding {
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 20px;
        margin-right: 20px;
    } */


/* Effect css  */


/* .navbar .button-wrap {
        text-align: center;
    } */


/* 
    .navbar .button {
        position: relative;
        display: inline-block;
        padding: 0;
        border: 0;
        background: none;
        color: #286aab;
        font-size: 1.2em;
        transition: color 0.7s;
        cursor: pointer;
        top: 1px;
    } */


/* .navbar .button.button-click,
    .navbar .button:focus {
        outline: none;
        color: #3c8ddc;
    }

    .navbar .button:after {
        content: "";
        position: absolute;
        top: 25%;
        left: 15%;
        margin: -20px 0 0 -20px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        opacity: 0;
    }

    effect b==============
    .navbar .button-effect-b:after {
        width: 55px;
        height: 48px;
        border: 10px solid rgba(111, 148, 182, 0.1);
    }

    .navbar .button-effect-b.button-click:after {
        -webkit-animation: anim-effect-b 0.3s forwards;
    }

    @-webkit-keyframes anim-effect-b {
        0% {
            -webkit-transform: scale3d(0.3, 0.3, 1);
        }

        25%,
        50% {
            opacity: 1;
        }

        100% {
            opacity: 0;
            -webkit-transform: scale3d(1.2, 1.2, 1);
        }
    }
}

@media only screen and (max-width: 767px) {
    .nav_padding {
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media only screen and (min-width: 767px) {
    .nav_padding {
        margin: auto;
    }
} 

@media only screen and (max-width: 500px) {
  
.AddTopPadding {
    padding-top: 52px;
}
    /* .imgStyFooterlogo {
        width: 40%;
        margin-right: 8px;
    } 

    .logoCenterText2 {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        padding: 0px 0px 16px;
        display: flex;
        align-items: center;
    }

    .logoCenterText2_footer {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        padding: 0px 0px 16px;
    }

    /* .bar1,
    .bar2,
    .bar3 {
        width: 16px;
        height: 2px;
        background-color: #4D45D4;
        margin: 4px 0;
        transition: 0.4s;
    } */


/* .navbar-brand {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 3px;
    } 

    .logoDisplay1 {
        display: none;
    }

    .get_start_btn {
        padding: 20px 40px;
        border: 1px solid #4D45D4;
        background: #4D45D4;
        ;
        font-size: 16px;
        color: #ffffff;
        border-radius: 4px;
        font-weight: 600;
        line-height: 24px;
    }
/* 
    .navbar-nav {
        width: 100%;
    }

    .navbarMargin {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .NavLinkStyle li a {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding-top: 32px;
    } 

    .form-inline {
        padding-top: 55px;
        text-align: center;
        padding-left: 6rem;
    }

    .ulstylink li {
        padding-left: 0rem;
        align-items: start;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding: 8px 20px;
    } 
}

@media only screen and (max-width: 428px) {
    .firstBlock_para_text {
        font-size: 16px;
        margin-right: 0rem;
        text-align: start;
        margin-right: 0rem;
    }
}

@media only screen and (max-width: 425px) {

    /* .bar1,
    .bar2,
    .bar3 {
        width: 16px;
        height: 2px;
        background-color: #4D45D4;
        margin: 4px 0;
        transition: 0.4s;
    } 
    .AddTopPadding {
        padding-top: 52px;
    }
    /* .navbar-brand {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 3px;
    }

    .logoDisplay1 {
        display: none;
    }

    .get_start_btn {
        padding: 20px 40px;
        border: 1px solid #4D45D4;
        background: #4D45D4;
        ;
        font-size: 16px;
        color: #ffffff;
        border-radius: 4px;
        font-weight: 600;
        line-height: 24px;
    }

    /* .navbar-nav {
        width: 100%;
    }

    .navbarMargin {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .NavLinkStyle li a {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding-top: 32px;
    } 

    .form-inline {
        padding-top: 55px;
        text-align: center;
    }

    .ulstylink li {
        padding-left: 0rem;
        align-items: start;
    }

    /* .navbar-expand-lg .navbar-nav .nav-link {
        padding: 8px 20px;
    } 

    .firstBlock_para_text {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        color: #1b1b1eCC;
        padding-top: 12px;
        margin-right: 0rem;
        margin-bottom: 0rem;
        text-align: center;
    }

    .imgStyFooterlogo {
        width: 40%;
        margin-right: 8px;
    }
}

@media only screen and (max-width: 384px) {
    .NavLinkStyle li a {
        font-size: 18px;
        line-height: 6px;
    }

    .form-inline {
        padding-top: 55px;
    }
}

@media only screen and (max-width: 375px) {
    .logoCenterText2 {
        font-size: 22px;
    }

    .logoDisplay1 {
        display: none;
    }

    /* .navbar-nav {
        width: 100%;
        padding-top: 40px;
       
    }

    .navbarMargin {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .logoCenterText2 {
        font-size: 18px !important;
    }

    /* .NavLinkStyle li a {
        font-size: 16px;
        line-height: 6px;
    } 

    .form-inline {
        padding-top: 55px;
        text-align: center;
    }
/* 
    .navbar-expand-lg .navbar-nav .nav-link {
        padding: 15px 20px;
        margin: 5px 0px;
    } 
}

@media only screen and (max-width: 320px) {
    .logoCenterText2 {
        font-size: 18px !important;
    }

    .firstBlock_Text {
        font-size: 1.3rem;
        line-height: 25px;
    }

    .get_start_btn {
        font-size: 16px;
    }
/* 
    .navbarMargin {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .logoCenterText2 {
        font-size: 18px !important;
    }

    .NavLinkStyle li a {
        font-size: 16px;
        line-height: 6px;
    } */


/* animation css 

@media (max-width: 991.98px) {
   .offcanvas-collapse {
        position: fixed;
        top: 85px;
        bottom: 0;
        right: -100%;
        width: 100%;
       
        visibility: hidden;
        background-color: #ffffff;
        border-top: 0.5px solid rgba(27, 27, 30, 0.16);
        transition: transform 0.6s ease-in-out, visibility 0.6s ease-in-out;
    } 
    /* .offcanvas-collapse.open {
        visibility: visible;
        transform: translateX(-100%);
    }

    .navbar-expand-lg .navbar-collapse {
        display: block !important;
        flex-basis: auto;
        visibility: visible;
        justify-content: start;
        /* background: #191C1A00; 
    } 

    .ulstylink {
        margin-right: 60px;
        align-items: start;
    }

    .ulstylink li {
        padding-left: 0rem;
        align-items: start;
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
    }

    .navbar-brand .form-inline {
        margin: auto !important;
    }

    .navbar-nav .form-inline {
        margin: auto;
    }

    .addPaddingBlock {
        padding-top: 40px;
    }
} */

.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.834);
    z-index: 1;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #3d5af1 transparent #3d5af1 transparent;
    border-radius: 50%;
    -webkit-animation: spin-anim 1.2s linear infinite;
            animation: spin-anim 1.2s linear infinite;
}

@-webkit-keyframes spin-anim {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes spin-anim {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.field {
    position: relative;
}

label,
input {
    transition: all 0.2s;
    touch-action: manipulation;
}

input {
    border: 0;
    border: 0.5px solid #191c1a;
    font-family: 'Inter';
    -webkit-appearance: none;
    border-radius: 4px;
    padding: 18px 20px;
    cursor: text;
}

input:focus {
    outline: 0;
    border: 2px solid #008836;
    border-radius: 8px;
}

input:placeholder-shown+label {
    cursor: text;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform: translate(0, 3.1rem) scale(1);
            transform: translate(0, 3.1rem) scale(1);
    margin-left: 30px;
    border-radius: 8px;
    /* font-size: 14px; */
    color: #1B1B1EE0;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    position: absolute;
    left: 0;
    bottom: 76px;
}

.field input::-webkit-input-placeholder {
    opacity: 0;
    -webkit-transition: inherit;
    transition: inherit;
}

.field input:focus::-webkit-input-placeholder {
    opacity: 0.25;
}

input:not(:placeholder-shown)+label,
input:focus+label {
    -webkit-transform: translate(0, 0) scale(1);
            transform: translate(0, 0) scale(1);
    cursor: pointer;
    background: linear-gradient(to top, #ffffff 60%, transparent 40%);
    padding: 0px 4px;
    position: absolute;
    left: 12px;
    bottom: 50px;
    margin-left: 12px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
}

input.parsley-error+label,
input:focus {
    color: #1B1B1ECC !important;
}

input:not(:placeholder-shown)+label {
    -webkit-transform: translate(0, 0) scale(1);
            transform: translate(0, 0) scale(1);
    cursor: pointer;
    /* left: 0; */
}

.lable_spanbg {
    padding: 0px 4px;
}

input:placeholder-shown+label :focus {
    position: absolute;
    left: 12px;
}

input:focus+label {
    color: #008836;
}

input.parsley-error:focus+label {
    color: red !important;
}

.lableSty {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #1b1b1e90;
}

.inputparent_marg {
    padding: 10px
}

@media only screen and (max-width: 767px) {
    .inputparent_marg {
        padding: 8px 0px;
    }
    input:placeholder-shown+label {
        margin-left: 16px;
    }
    input:not(:placeholder-shown)+label,
    input:focus+label {
        -webkit-transform: translate(0, 0) scale(1);
                transform: translate(0, 0) scale(1);
        cursor: pointer;
        background: linear-gradient(to top, #ffffff 60%, transparent 40%);
        padding: 0px 4px;
        position: absolute;
        left: 0px;
        bottom: 50px;
        margin-left: 12px;
    }
}


/* .field {
  position: relative;
  background-color: rgb(234, 137, 19);
}

label,
input {
  transition: all 0.2s;
  touch-action: manipulation;
}

input {
  border: 0;
  border: 0.5px solid #ffffff;
  font-family: 'Inter';
  -webkit-appearance: none;
  border-radius: 0;
  padding: 18px 20px;
  cursor: text;
}
input:focus {
  outline: 0;

  border: 0.5px solid #4D45D4;
  border-radius: 8px;
}

input:placeholder-shown+label {
  cursor: text;
  max-width: 66.66%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: left bottom;
  transform: translate(0, 3.1rem) scale(1);
  margin-left: 30px;
  border-radius: 8px;
  color: #1B1B1EE0;
  font-weight: 500;
  line-height: 24px;
  position: absolute;
  left: 0;
  bottom: 76px;

}

::-webkit-input-placeholder {
  opacity: 0;
  transition: inherit;
}

input:focus::-webkit-input-placeholder {
  opacity: 0.25;
}

input:not(:placeholder-shown)+label,
input:focus+label {
  transform: translate(0, 0) scale(1);
  cursor: pointer;
  background: linear-gradient(to top, #ffffff 60%, transparent 40%);
  padding: 0px 4px;
  position: absolute;
  left: 0;
  bottom: 52px;
  margin-left: 22px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

input.parsley-error+label,
input:focus {
  color: #1B1B1ECC !important;
}

input:not(:placeholder-shown)+label,
input:focus+label {
  transform: translate(0, 0) scale(1);
  cursor: pointer;
}

.lable_spanbg {
  padding: 0px 4px;
}

input:placeholder-shown+label :focus {
  position: absolute;
  left: 0;
}

input:focus+label {
  color: #4D45D4 !important;
}

input.parsley-error:focus+label {
  color: red !important;
}

.lableSty {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #1b1b1e90;
}

.inputparent_marg {
  padding: 10px
}

@media only screen and (max-width: 425px) {
  input {
    padding: 13px 20px;
  }

  input:placeholder-shown+label {
    bottom: 74px;
  }

  input:not(:placeholder-shown)+label,
  input:focus+label {
    bottom: 52px;
  }

  input:placeholder-shown+label {
    transform: translate(1, 2rem) scale(1);
  }

  .inputparent_marg {
    padding: 8px 0px;
  }

  input:placeholder-shown+label {
    margin-left: 8px 0px;
  }

  input:not(:placeholder-shown)+label,
  input:focus+label {
    margin-left: 12px !important;
  }

  input:placeholder-shown+label {
    margin-left: 20px;
  }

  input:placeholder-shown:focus+label {
    margin-left: 12px;
  }

  .AddPadingCircleArrow {
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (max-width: 375px) {

  input:placeholder-shown+label {
    bottom: 71px;
  }

  input:not(:placeholder-shown)+label,
  input:focus+label {
    bottom: 45px;
  }
} */
/* ----------------WHY US------------------- */
.add_padding_top_sub_blc {
    padding: 0px 0px 60px;
}

.sequer_main {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin: 0;
    color: #26331fe0;
    padding-top: 16px;
}

.sequer_sub {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    margin: 0;
    text-align: center;
    padding-top: 2px;
    color: #26331fe0;
}

.secondBlockSty {
    background: #FFFFFF;
    padding: 80px 0px;
}

.AddMargin {
    padding: 20px;
    text-align: center;
}

.whyus_icon {
    width: 40px;
}

@media only screen and (max-width: 991px) {
    /* second block */

    .secondBlockSty {
        padding: 40px 0px 40px;
    }
}

@media only screen and (max-width: 767px) {

    /* whyus  */
    .sequer_sub {
        font-size: 14px;
        line-height: 16px;
    }

    .sequer_main {
        font-size: 16px;
        line-height: 19px;
        padding-top: 20px;
    }

    .BoxPara {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        margin-right: 0rem;
        padding-top: 8px;
    }

    .whyus_icon {
        width: 32px;
    }

    .AddMargin {
        margin: 0rem;
    }
      .addPaddingBlockFrist .second_block_parent_margin .container {
        padding-right: .0rem !important;
        padding-right: var(--bs-gutter-x, .0rem) !important;
        padding-left: .0rem !important;
        padding-left: var(--bs-gutter-x, .0rem) !important;
    }
}

/* 
.second_block_parent_margin{
 background-color: #fafaff;
}
.sequer_main {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin: 0;
    color: #1b1b1e;
    padding-top: 16px;
}

.sequer_sub {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    text-align: center;
    padding-top: 2px;
    color: #1b1b1e;
}

.secondBlockSty {
    padding-bottom: 40px;
}

.secondBlockSty {
    background: #FFFFFF;
    border-radius: 8px;
}

.AddMargin {
    padding: 20px;
    text-align: center;
}

.add_padding_top_sub_blc {
    padding: 40px 0px;
} */
/* --------------REQUEST DESKTOP------------------- */

.addPaddingBlock {
    padding: 80px 20px;
}

.GetstartButtonContainer_step {
    display: flex;
    align-items: center;
    justify-content: center;
}

.saprater {
    width: 100%;
    height: 2px;
    /* background: #191C1A4D */
}

.Display_nuber_Img {
    display: flex;
    align-items: center;
    margin-right: 19rem;
    margin-left: 5rem;
    padding-top: 80px;
}

.BoxParentStyle {
    padding-top: 80px;
    padding-bottom: 80px;
}

.BoxHeadding {
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    /* color: #26331fe0; */
    padding-top: 28px;
}

.BoxPara {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    /* color: #191C1ACC; */
    padding-top: 12px;
    margin-right: 0rem;
    margin-bottom: 0px;
}

.SingleBoxInnerCol5th {
    /* padding: 40px 32px; */
    margin: 0px 40px;
    height: 100%;
}

.MobilResponsiveDisplay2 {
    display: none;
}

.requesticon_bg {
    /* background: #FFFFFF; */
    /* box-shadow: 0px 0px 12px 2px #85bb651f; */
    border-radius: 10px;
    padding: 13.5px 12px;
    margin-top: 12px;
}

.steps_text1 {
    margin-bottom: 20px !important;
    margin-top: 24px;
}

.steps_text1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    /* color: #85BB65; */
    margin-bottom: 20px;
}

.steps_text1_mob {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 15px;
    display: flex;
    align-items: center;
    /* color: #85BB65; */
    padding: 0px 15px;
}

.GetstartButtonContainer {
    text-align: center;
    margin: auto;
    padding-top: 60px;
}

@media only screen and (max-width: 991px) {
    .all_blcok_head {
        text-align: center;
        margin-bottom: 60px;
        /* color: #26331fe0; */
    }
    /* request */
    .StyleMob5th {
        flex-direction: column !important;
    }
    .saprater {
        width: 2px;
        height: 150px;
        /* background: #191C1A00; */
    }
    .Main_Div_Number_Img {
        padding-bottom: 0px;
        padding-right: 20px;
    }
    .Display_nuber_Img {
        flex-direction: column;
        padding-top: 0px;
        margin-right: 1rem;
        margin-left: 0rem;
    }
    .MobilResponsiveDisplay1 {
        display: none;
    }
    .MobilResponsiveDisplay2 {
        display: block;
    }
    .seperator2 {
        height: 150px;
    }
    .all_blcok_head_request {
        margin-bottom: 0px;
    }
    .bolck_bg {
        /* background: #f9fffb; */
    }
    .AddmarginMobileRes {
        margin-bottom: 30px;
    }
    .BoxParentStyle {
        padding-top: 0px;
        padding-bottom: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .all_block_headRequestMob {
        margin-bottom: 12px;
    }
    .MobileImgWidth {
        width: 24px;
        height: 24px;
    }
    .Display_nuber_Img {
        margin-right: 0rem;
        margin-left: 0rem;
        padding-top: 36px;
    }
    .seperator2 {
        height: 156px;
    }
    /* request */
    .Main_Div_Number_Img {
        padding-bottom: 0px;
        margin-right: 20px;
    }
    .BoxHeadding {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        margin-top: 28px;
        padding-top: 0px;
    }
    .BoxParentStyle {
        padding-top: 40px;
        padding-bottom: 0px;
    }
    .requesticon_bg {
        /* background: #FFFFFF; */
        box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.12);
        border-radius: 10px;
        padding: 8px;
        margin-top: 12px;
    }
    .magin_three_img {
        padding: 0px 0px;
    }
    .saprater {
        height: 160px;
    }
}

@media only screen and (max-width: 370px) {
    .seperator2 {
        height: 156px;
    }
}

@media only screen and (max-width: 353px) {
    .saprater {
        width: 2px;
        height: 160px;
    }
    .seperator2 {
        height: 180px;
    }
}

@media only screen and (max-width: 333px) {
    .saprater {
        width: 2px;
        height: 175px;
    }
}

@media only screen and (max-width: 331px) {
    .saprater {
        width: 2px;
        height: 205px;
    }
}
/* --------------FAQ------------------- */

.accordion-flush .accordion-item:last-child {
    height: 100%;
}

.FaqBlockContainer {
    /* margin: 0px 8px; */
    padding-bottom: 80px 0px;
}

.FaqHeadding {
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
    color: #26331fe0;
    text-align: center;
}

.FaqBlockContainer .accordion-flush .accordion-item .accordion-button {
    border-radius: 6px;
    border: none;
}

.FaqBlockContainer .accordion-flush .accordion-item {
    border: 1px solid #191C1A1f ;
    border-radius: 6px;
    margin: 0px auto 8px;
    font-size: 20px;
    line-height: 24px;
}

.FaqBlockContainer .accordion-button {
    padding: 0px 0px;
}

.FaqBlockContainer .accordion {
    padding-top: 60px;
}

.FaqBoxHeadding {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;

    margin-bottom: 0px;
}

.accordion-button:not(.collapsed) {
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    color: #85BB65;
}

.FaqBlockContainer .accordion-button:not(.collapsed)::after {
    /* background-image: url(assets/images/FAQsarrow1.svg); */
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}

.FaqBlockContainer .accordion-button::after {
    /* background-image: url(assets/images/FAQs\ arrow.svg); */
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.FaqBlockContainer .accordion-button:focus {
    z-index: 3;
    border: none;
    outline: none;
    box-shadow: none;
}

.FaqBlockContainer .accordion-button:focus-within {
    border: none;
    box-shadow: none;
    border-radius: 20px;
}

.FaqBlockContainer .accordion-button:not(.collapsed) {
    background: #ffffff;
    border: 1px solid rgba(62, 54, 159, 0.12);
}

.FaqBlockContainer .accordion-button {
    background: #ffffff;
    border: 1px solid rgba(62, 54, 159, 0.12);
    box-shadow: 0px 3px 30px rgba(38, 33, 98, 0);
    border-radius: 20px;
}

.FaqBlockContainer .accordion-flush .accordion-item:hover {
    border-color: #191C1A ;
}



.FaqBlockContainer .accordion-flush .accordion-item:focus-within {
    border-color: #191C1A ;
}

.FaqBlockContainer .accordion-body {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    padding: 8px 72px 20px 8px;
    color: #191C1A99;
}

.FaqBlockContainer .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: calc(1.25rem - 1px);
    border-bottom-left-radius: calc(1.25rem - 1px);
}

.FaqPadding {
    padding-top: 40px;
    padding-bottom: 120px;
}
.FaqBoxHeadding {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0px;
}
.accordion-item {
    max-width: 620px;
    margin: 0px auto;
    border-radius: 6px;
    padding: 16px 20px;
}

@media only screen and (max-width: 991px) {
    .FaqBlockContainer .accordion-button {
        padding: 0px 0px;
    }

    /* faq */

    .FaqBoxHeadding {
        font-size: 16px;
        line-height: 19px;
    }

    .FaqBlockContainer .accordion {
        padding-top: 40px;
        padding-bottom: 0px;
    }

    .FaqBlockContainer .accordion-flush .accordion-item {
        margin: 0px 10px 12px;
        height: auto;
    }
}

@media only screen and (max-width: 767px) {

    /* faq */
    .FaqHeadding {
        font-size: 1.6rem;
    }

    .FaqBoxHeadding {
        font-size: 16px;
        line-height: 19px;
    }

    .accordion-body {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
    }


    .FaqBlockContainer .accordion-flush .accordion-item:hover {
        border-color: #191C1A1f !important;
    }

    .FaqBlockContainer .accordion {
        padding-top: 0px;
        /* padding-bottom: 80px; */
    }

    .FaqHeadding {
        font-size: 32px;
        font-weight: 700;
        line-height: 39px;
    }

    .FaqPadding {
        padding-top: 20px;
        padding-bottom: 78px;
    }

    .FaqBlockContainer .accordion-button {
        box-shadow: 0px 3px 30px rgb(38 33 98 / 0%);
    }

    .accordion-button {
        padding: 10px 20px;
    }

    .FaqBlockContainer .accordion-flush .accordion-item {
        margin: 12px 0px;
        padding: 0px 0px;
        height: auto;
        border-radius: 6px;
        border: 0.5px solid #191C1A14 !important;
    }

    .FaqBlockContainer .accordion-button {
        padding: 0px;
    }

    .accordion-button::after {
        background-size: 1rem;
    }

    .accordion-body {
        padding: 2px 20px;
    }

    .FaqBlockContainer .accordion-body {
        font-weight: 400 !important;
        color: #26331fe0;
    }

    .accordion-body p {
        margin-bottom: 5px !important;
    }

    .addPaddingBlock {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .FaqBlockContainer .accordion-body {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding: 0px 20px 10px 20px;
        color: #191C1A99;
    }

    .FaqBlockContainer .accordion-button {
        padding: 12px 16px;
    }

    .accordion-button {
        box-shadow: 0px 3px 30px rgb(38 33 98 / 0%);
    }

    .accordion-button:not(.collapsed) {
        box-shadow: 0px 3px 30px rgb(38 33 98 / 0%);
    }

    .paddingfaqblock {
        padding: 80px 8px;
    }
}

/* 7thBlock Faq
.bolck_bg {
    background: #FAFAFF;
}
.FaqBlockContainer {
    margin: 0px 8px;
}

.FaqHeadding {
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
    color: #1b1b1e;
    text-align: center;
}

.FaqBlockContainer .accordion-flush .accordion-item .accordion-button {
    border-radius: 8px;
    border: none;
}

.FaqBlockContainer .accordion-flush .accordion-item {
    border: 1px solid #1b1b1e1f !important;
    border-radius: 8px;
    margin: 20px 0px;
}

.FaqBlockContainer .accordion-button {
    padding: 20px 32px;
}

.FaqBlockContainer .accordion {
    padding-top: 40px;
    padding-bottom: 80px;
}

.FaqBoxHeadding {
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 0px;
}

.accordion-button:not(.collapsed) {
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    color: #4D45D4;
}

.FaqBlockContainer .accordion-button:not(.collapsed)::after {
    background-image: url(static/assets/images/FAQsarrow1.svg);
    transform: rotate(0deg);
}

.FaqBlockContainer .accordion-button::after {
    background-image: url(static/assets/images/FAQs\ arrow.svg);
    transform: rotate(-90deg);
}

.FaqBlockContainer .accordion-button:focus {
    z-index: 3;
    border: none;
    outline: none;
    box-shadow: none;
}

.FaqBlockContainer .accordion-button:focus-within {
    border: none;
    box-shadow: none;
    border-radius: 20px;
}

.FaqBlockContainer .accordion-button:not(.collapsed) {
    background: #ffffff;
    border: 1px solid rgba(62, 54, 159, 0.12);
}

.FaqBlockContainer .accordion-button {
    background: #ffffff;
    border: 1px solid rgba(62, 54, 159, 0.12);
    box-shadow: 0px 3px 30px rgba(38, 33, 98, 0);
    border-radius: 20px;
}

.FaqBlockContainer .accordion-flush .accordion-item:hover {
    border-color: #1b1b1e !important;
}

.FaqBlockContainer .accordion-flush .accordion-item:focus-within {
    border-color: #1b1b1e !important;
}

.FaqBlockContainer .accordion-body {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    padding: 0px 72px 20px 32px;
    color: #1b1b1e99;
}

.FaqBlockContainer .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: calc(1.25rem - 1px);
    border-bottom-left-radius: calc(1.25rem - 1px);
}

.FaqPadding {
    padding-top: 40px;
    padding-bottom: 120px;
}
@media only screen and (max-width: 991px){
.FaqBoxHeadding {
    font-size: 16px;
}
}
@media only screen and (max-width: 768px){
.FaqBoxHeadding {
    font-size: 20px;
}
}
@media only screen and (max-width: 500px){
.FaqBoxHeadding {
    font-size: 16px;
}}
@media only screen and (max-width: 425px){
.FaqBoxHeadding {
    font-size: 16px;
}
.accordion-button::after {
    width: 1rem;
    height: 1rem;
}
.accordion-button::after {
    background-size: 1rem;
}
}
@media only screen and (max-width: 320px){
.FaqBoxHeadding {
    font-size: 12px;
}
} */
/* 8th */

.ready_margin {
    padding: 60px 8px;
}

.HeadingGetStart {
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    color: #26331fe0;
    text-align: center;
    padding-top: 40px;
}

.ParaGetStart {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    max-width: 758px;
    line-height: 24px;
    text-align: center;
    color: rgba(27, 27, 30, 0.8);
    padding-top: 20px;
    margin: 0px auto;
}

.WhiteButtonGetStart {
    padding: 20.5px 40px;
    /* border: 1px solid #85BB65; */
    /* background: #85BB65; */
    font-size: 15px;
    color: #ffffff;
    font-weight: 500;
    border-radius: 6px;
    /* box-shadow: 0px 3px 8px rgba(25, 28, 26, 0.24); */
}

.WhiteButtonGetStart:hover {
    background: #0B622D;
    color: #ffffff;
}

.steps_text1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    /* color: #2d2926; */
    margin-bottom: 12px;
}

.steps_text1_mob {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 15px;
    display: flex;
    align-items: center;
    /* color: #85BB65; */
    padding: 0px 15px;
}

.GetstartButtonContainer {
    text-align: center;
    margin: auto;
    padding-top: 60px;
    /* padding-bottom: 80px; */
}

@media only screen and (max-width: 991px) {

    /* Ready to request */

    .requeststeps {
        margin: 42px 0px 20px;
        display: flex;
    }

    .BoxPara {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .GetstartButton_request {
        padding-bottom: 60px;
    }

}

@media only screen and (max-width: 767px) {


    /* ready  */
    .ready_margin {
        padding: 40px 8px;
    }

    .GetstartButtonContainer_mob {
        padding-top: 80px;
        text-align: center;
    }

    .all_blcok_head_request {
        margin-bottom: 0px;
    }

    .HeadingGetStart {
        font-size: 32px;
        font-weight: 700;
        line-height: 39px;
        text-align: center;
        padding-top: 40px;
    }

    .ParaGetStart {
        padding-top: 20px;
        padding-bottom: 0px;
        margin-bottom: 0px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: rgba(27, 27, 30, 0.8);
    }

    .ParaGetStart {
        width: 100%;
    }

    .WhiteButtonGetStart {
        padding: 16.5px 32px;
        font-size: 16px;
        margin-top: 10px;
    }

    .GetstartButtonContainer {
        padding: 40px 0px 0px;
    }

}



/* 8th

.requestmoneyblock {
    background: #FFFFFF;
}
.top_ready {
    padding-top: 48px;
}
.ParaGetStart {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: rgba(27, 27, 30, 0.8);
    padding-top: 20px;
}
.steps_text1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #4D45D4;
    padding: 20px 45px;
}

.steps_text1_mob {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #4D45D4;
    padding: 0px 15px;      
}

@media only screen and (max-width: 1024px){
    .ParaGetStart {
        font-size: 16px;
        padding-top: 20px;
        text-align: center;
    }
    .top_ready {
        padding-top: 48px;
    }
    
} */
/* 9th footer Block */

.FooterBlockContainer {
  padding: 60px 0px 60px 0px;
}

.FooterLogoSty {
  font-size: 40px;
  font-weight: 600;
  color: #FFFFFF;
}

.FooterLogoPara {
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFFCC;
  line-height: 19.5px;
  margin-top: 12px;
  width: 40%;
}

.FooterLink {
  padding-top: 22px;
}

.FooterLink p {
  color: #FFFFFF99;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 18px;
}

.FooterPara {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: #FFFFFF99;
  margin-bottom: 0px;
  padding: 78px 0px 0px;
}

.OlaImgsty {
  padding-top: 64px;
}

.bolck_bg_footer {
  background: #26331f;
}

.logoCenterText2_footer {
  font-weight: 600;
  font-size: 24px;
  line-height: 0px;
  color: #85BB65 !important;
  padding-right: 8px;
  width: 30%;
}

.logoCenterTextFooter {
  color: white;
}

.FooterLink a {
  cursor: pointer;
}

.FooterLink p:hover {
  color: #FFFFFF;
  font-weight: 600;
  text-decoration: underline;
}
.FooterFaqs_main .accordion-item{
max-width: 100%;
}
.FooterFaqs_main .FaqBlockContainer .accordion {
  padding-top: 0px;
}
.ola_logo{
  padding-top: 20px;
  width: 50px;
}

@media only screen and (max-width: 991px) {
  .imgStyFooterlogo {
    width: 40px;
    height: 40px;
    margin-right: 8px;
}
.logoCenterText2_footer {
  line-height: 28px;
}

.imgStyFooterlogo2 {
  /* width: 10.64%; */
  margin-right: 8px;
}

.FooterLink {
  padding-top: 0px;
}

.FooterBlockContainer {
  padding: 80px 20px 60px 20px;
}

.OlaImgsty {
  padding-top: 64px;
}

.FooterLogoPara {
  width: 195px;
}

.AddPaddingContactForm {
  padding: 40px 10px;
}
}
@media screen and (min-width: 500px) and (max-width: 767px) {

  .FooterLogoPara {
    width: 279px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    margin-bottom: 0px;
}

.FooterLink {
    padding-top: 60px;
}

.FooterLink p {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 16px;
}

.FooterPara {
    padding: 44px 0px 40px;
    text-align: justify;
}

.FooterBlockContainer {
    padding: 40px 8px;
}


.FooterLogoSty {
    font-size: 22px;
}

.imgStyFooterlogo {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.imgStyFooterlogo2 {
    /* width: 24px;
    height: 24px; */
    margin-right: 8px;
}


.logoCenterText2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    padding: 16px 0px 16px;
    display: flex;
    align-items: center;
}

.logoCenterText2_footer {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    padding: 0px 0px 16px;
}
.FooterPara {
  
  text-align: justify;
}
}
@media screen and (min-width: 320px) and (max-width: 500px) {
  .FooterLink p {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 16px;
}
.ola_logo{
  padding-top: 10px;
  padding-bottom: 10px;
  width: 50px;
}
.FooterPara {
  
  text-align: justify;
}
}
body {
    font-family: 'Inter' !important;
    font-size: 18px;
}

* {
    box-sizing: border-box;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0rem;
}

a {
    text-decoration: none;
}


/* comman  Mar */

.form_block.navBg {
    position: relative !important;
}

.navbar.form_block>.container {
    justify-content: center;
}

.form_block .logoCenterText2 {
    justify-content: center;
}

.form_block .imgStyFooterlogo {
    width: 100%;
}

.all_blcok_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    /* color: #26331fe0; */
}

.cus_sty_getstart {
    margin-left: 12px;
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
    .col-lg-6 {
        padding: 0px !important;
    }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
    .container {
        max-width: 500px;
    }
}

@media only screen and (min-width: 1440px) {
    .navbarMargin {
        padding-left: 19rem;
        padding-right: 19rem;
    }
    .logoCenterText2 {
        display: flex;
    }
    .DisplayNoneFooterlogo {
        display: block;
    }
    .imgArrowDisplymob {
        display: none;
    }
    .imgArrowDisplydesk {
        display: contents;
        justify-content: space-between;
    }
    .LinkDisplayShowNot {
        display: none;
    }
    .ArrowIconParent .icon {
        fill: #26331fe0;
    }
}

@media only screen and (min-width: 2560px) {
    .navbarMargin {
        padding-left: 39rem;
        padding-right: 39rem;
    }
    /* .MobilResponsiveDisplay2 {
        display: none;
    } */
    .logoDisplay1 {
        display: block;
    }
    .logoDisplay2 {
        display: none;
    }
    .DisplayNoneFooterlogo {
        display: block;
    }
    .LinkDisplayShowNot {
        display: none;
    }
    .amount_but {
        padding: 18px 20px;
    }
}

@media only screen and (max-width: 1440px) {
    .navbarMargin {
        padding-left: 5rem;
        padding-right: 5.5rem;
    }
    .logoDisplay2 {
        display: block;
    }
    .imgArrowDisplymob {
        display: none;
    }
    .imgArrowDisplydesk {
        display: block;
    }
    .LinkDisplayShowNot {
        display: none;
    }
}

@media only screen and (max-width: 1200px) {
    .logoCenterText2 {
        font-size: 24px;
        display: flex;
        width: 24%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .BannerImgFirstBlock {
        padding-top: 34%;
    }
}

@media only screen and (max-width: 1024px) {
    .navbarMargin {
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .imgArrowDisplymob {
        display: none;
    }
    .imgArrowDisplydesk {
        display: block;
    }
    .LinkDisplayShowNot {
        display: none;
    }
    .logoDisplay1 {
        display: none;
    }
    .logoDisplay2 {
        display: block;
        padding: 14px 0px 0px;
    }
    .NavLinkStyle li a {
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
        color: #191C1Acc !important;
    }
}

@media only screen and (max-width: 320px) {
    /* .AddPadingCircleArrow a {
        font-size: 14px;
    } */
    .offcanvas-collapse {
        top: 48px !important;
    }
    .AddPadingCircleArrow {
        padding: 0px 0px 0px 0px !important;
    }
    .ModalHeaddingText {
        font-size: 25px;
    }
    .modal-body {
        padding: 12px;
    }
    .ModalContactHeaddingText {
        font-size: 24px;
    }
}

.get_start_btn {
    padding: 1rem 1.5rem;
    /* border: 1px solid #85BB65; */
    /* background: #85BB65; */
    font-size: 16px;
    /* color: #ffffff; */
    font-weight: 600;
    border-radius: 6px;
    line-height: 24px;
    font-family: 'Inter';
}

.get_start_btn:hover {
    background: #0B622D;
    color: #ffffff;
}

.amount_but:hover {
    border: 1px solid #85BB65;
    /* margin-bottom: -4px; */
}

.amount_but {
    padding: 16px;
    width: 100%;
    /* background: #FFFFFF; */
    border: 0.5px solid #191C1A33;
    border-radius: 6px;
    color: #26331fe0;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    height: 53px;
}

.ArrowIconParent {
    /* border: 1px solid #85BB65; */
    /* background: #85BB65; */
    border-radius: 6px;
    box-shadow: 0px 3px 8px rgba(25, 28, 26, 0.24);
    padding: 18.5px 32px;
    display: flex;
    align-items: center;
}

.ArrowIconParentbanner {
    /* border: 1px solid #85BB65; */
    /* background: #85BB65; */
    border-radius: 6px;
    width: 100%;
    max-width: 376px;
    padding: 14px 32px;
    align-items: center;
}

.buttonText {
    display: flex;
    justify-content: space-between;
}

.AddPadingCircleArrow a {
    font-size: 16px;
    letter-spacing: 1px;
    font-family: 'Inter';
    font-weight: 500 !important;
    line-height: 19px;
    color: #ffffff !important;
}

.ArrowIconParent:hover {
    color: #ffffff;
    background: #0B622D;
}

.WhiteButtonGetStart {
    padding: 20.5px 40px;
    /* border: 1px solid #85BB65; */
    /* background: #85BB65; */
    font-size: 15px;
    /* color: #ffffff; */
    font-weight: 500;
    border-radius: 6px;
    /* box-shadow: 0px 3px 8px rgba(25, 28, 26, 0.24); */
}

@media only screen and (max-width: 991px) {
    .get_start_btn {
        line-height: 24px;
        padding: 16px 40px;
        /* border: 1px solid #85BB65; */
        /* background: #85BB65;*/
        font-size: 20px;
        margin: 0px auto;
        color: #ffffff;
        border-radius: 6px;
    }
    .AddPadingCircleArrow a {
        font-family: 'Inter';
        font-weight: 500 !important;
        line-height: 19px;
        color: #ffffff !important;
    }
    .all_blcok_head {
        font-weight: 600;
        font-size: 48px;
        line-height: 58px;
        text-align: center;
        margin-bottom: 60px;
        /* color: #26331fe0; */
    }
    .AddMargin {
        padding: 20px 0px;
    }
    .AddmarginMobileRes {
        margin-bottom: 30px;
    }
    .Get_btn_Mobile {
        background: #ffffffe0;
        padding: 16px 40px;
        /* border: 1px solid #85BB65; */
        /* background: #85BB65; */
        font-size: 20px;
        /* color: #ffffff; */
        font-weight: 500;
        border-radius: 6px;
        line-height: 24px;
    }
    .imgArrowDisplymob {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .imgArrowDisplydesk {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .addpaddingBolock {
        padding-top: 0px;
    }
    .get_start_btn {
        padding: 20px 40px;
        /* border: 1px solid #85BB65; */
        /* background: #85BB65; */
        font-size: 16px;
        color: #ffffff;
        border-radius: 6px;
        margin: 0px auto;
        font-weight: 600;
        line-height: 24px;
    }
    .add_padding_top_sub_blc {
        padding: 0px;
    }
    .amount_but {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        padding: 16px;
        border: 1px solid #191C1A1F;
    }
    .all_blcok_head {
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 40px;
    }
    .ArrowIconParentbanner {
        width: 100%;
    }
}

@media only screen and (min-width: 320px) and (max-width: 500px) {
    .all_blcok_head {
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 40px;
    }
}

.field dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 0.5rem !important;
}

.FormHeaderSty {
    justify-content: center !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
}

input::-moz-placeholder {
    opacity: 0 !important;
}

.firstBlock_Text_parent input:focus::-moz-placeholder {
    opacity: 0.4 !important;
}


/* comman-------------=============

.heyy_ra {
    background-color: #85BB65;
}

.offcanvas.offcanvas-end {
    top: 70px;
    width: 100%;
}

body {
    font-family: 'Inter' !important;
    font-size: 18px;
}

* {
    box-sizing: border-box;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0rem;
}

a {
    text-decoration: none;
}

.cus_sty_getstart {
    margin-left: 12px;
}

.row {
    margin-right: calc(var(--bs-gutter-x) * -0);
    margin-left: calc(var(--bs-gutter-x) * -0);
}

.parent_container .container {
    padding-right: var(--bs-gutter-x, 20px) !important;
    padding-left: var(--bs-gutter-x, 20px) !important;
}

.parent_container .second_block_parent_margin {
    margin-left: -17px;
    margin-right: -17px;
    padding-top: 0px;
}

.all_blcok_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #1b1b1e;
}

.GetstartButtonContainer_step {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.WhiteButtonGetStart {
    padding: 16px 24px;
    border: 1px solid #FAD419;
    background: #FAD419;
    font-size: 15px;
    color: #1B1B1E;
    font-weight: 500;
    border-radius: 4px;
}

.WhiteButtonGetStart:hover {
    background: #F2BD00;
    box-shadow: 0px 3px 8px rgb(25 28 26 / 24%);
    color: #1B1B1E;
}

.GetstartButtonContainer {
    text-align: center;
    margin: auto;
    padding-top: 40px;
    padding-bottom: 80px;
} */


/* ------------Modal Final-------------- */


/* ===========================================Modal Css========================================= */

.ModalHeaddingText {
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    color: #26331fe0;
    margin: 0px;
    padding-bottom: 20px;
}

.closeIconModale {
    width: 25px;
    height: 25px;
    font-size: 19px;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #191C1A33;
    color: #26331fe0;
    background: #efefef;
}

.modelpara {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: #191C1ACC;
}

.faqbtnSty {
    border: 0px;
    background: none !important;
    padding: 0px;
}

.closeBtnSty {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: #26331fe0;
    padding: 10px 20px;
    border: 1px solid #26331fe0;
    border-radius: 6px;
}

.closeBtnSty:hover {
    color: #26331fe0;
    background: rgba(62, 54, 159, 0.08);
}

.mainParaSty {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: #26331fe0;
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 40px 40px 4px 40px;
    border-bottom: none;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 40px 40px;
}

.modal-footer {
    padding: 80px 0px 0px 0px;
    border-top: none;
}

.ModalFaqs .accordion-button:not(.collapsed) {
    color: #0c63e4;
    background-color: #e7f1ff;
}

.ModalFaqs .accordion-button:focus {
    z-index: 3;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
}

.ModalFaqs .accordion-button:not(.collapsed) {
    color: #0c63e4;
    background-color: #e7f1ff;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 0%);
}

.ModalFaqs .accordion-item {
    background-color: #fff;
    border: none;
    margin-top: 15px;
}

.ModalFaqs .accordion-button::after {
    content: none;
}

.ModalFaqs .modelpara {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #191C1ACC;
}

.borderAdd {
    border-bottom: 1px solid;
    text-align: start;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #26331fe0;
}

.modal {
    overflow-x: hidden;
    overflow-y: hidden;
}

.modalinputPadding {
    padding: 15px !important;
    border: none;
    background: #f3f3f3;
    border-radius: 12px;
    border: 2px solid #f3f3f3;
}

.twoInputStyFlName {
    margin-bottom: 34px;
    margin: 10px 10px;
}

.modal-body {
    max-height: calc(100vh - 10px);
    overflow-y: auto;
}

.btnSubscribSty {
    background: #85BB65;
    padding: 5px;
    color: #fff;
    border-radius: 5px;
    border: 1px solid #85BB65;
}

.ModalHeaddingSty {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.btnSubmitSty {
    background: #85BB65;
    border: 1px solid #85BB65;
    border-radius: 6px;
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    padding: 15px !important;
    border: none;
    cursor: pointer;
}


/* Contact us Model Css */

.addFormMargin .form-control:hover {
    background: #ffffff;
    border: 2px solid #191C1A4d;
    box-shadow: 0px 3px 20px rgba(62, 54, 159, 0.12);
    border-radius: 12px;
}

.addFormMargin .form-control:focus {
    background: #ffffff;
    border: 2px solid #26331fe0;
    box-shadow: 0px 3px 20px rgba(62, 54, 159, 0.12);
    border-radius: 12px;
}

.addFormMargin .form-control:focus-visible {
    background: #ffffff;
    border: 2px solid #26331fe0;
    box-shadow: 0px 3px 20px rgba(62, 54, 159, 0.12);
    border-radius: 12px;
    outline: none;
}


/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #f3f3f3 inset;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}

.ModalContactHeaddingText {
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    color: #26331fe0;
    margin: 0px;
}

.closeIconModaleContact {
    width: 25px;
    height: 25px;
    font-size: 19px;
    text-align: center;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #191C1A33;
    color: #26331fe0;
    margin-top: 0px;
    margin-right: 0px;
}

.ModalFaqs .modelparaContact {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #191C1ACC;
}

.ModalFaqsContact {
    color: #26331fe0;
    font-weight: 700;
}

.ModalFaqsContact:hover {
    color: #26331fe0;
    font-weight: 700;
}

.AddPaddingContactForm {
    padding: 40px 90px;
}

.inputLableSty {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin: 12px 0px 8px 4px;
}

html {
    scroll-behavior: smooth;
}

#inputselect {
    width: 100%;
    scroll-margin-block-start: 110px;
    scroll-margin-block-end: 110px;
}

.imgStyFooterlogo {
    width: 10.64%;
    margin-right: 8px;
}

.imgStyFooterlogo2 {
    margin-right: 8px;
}

.FaqBoxHeadding {
    /* font-size: 20px; */
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0px;
}


/* contact Model css */

#addressPlaceholder {
    padding-top: 30px;
}

#addressPlaceholder,
#phonePlaceholder,
#emailPlaceholder {
    text-align: center;
    margin: 15px auto;
    color: #1b1b1e;
    font-weight: 700;
}

#contactText {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #1b1b1eCC;
    text-align: center;
}

.error_color {
    width: 100%;
    list-style-type: none;
    font-size: 12px;
    line-height: 20px;
    height: 20px;
    font-weight: 500;
    line-height: 17px;
    color: #ff2b2b;
    border-top: none;
    border-radius: 0 0 4px 4px;
    padding: 0px 10px;
    text-align: start;
    transition: all 0.2s ease;
    margin-bottom: 0px;
}


/*  */

.main-div {
    height: auto;
    min-height: 100vh;
    width: 100%;
}

.dots {
    position: relative;
    width: 150px;
    height: 50px;
    -webkit-transform: scale(0.25, 0.25);
            transform: scale(0.25, 0.25);
}

.dot {
    width: 53px;
    height: 53px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    opacity: 0;
    border-radius: 50%;
    background-color: #EB275F;
}

.dot-1 {
    -webkit-animation: animation-dots 1.5s infinite linear 1s;
            animation: animation-dots 1.5s infinite linear 1s;
}

.dot-2 {
    -webkit-animation: animation-dots 1.5s infinite linear 0.5s;
            animation: animation-dots 1.5s infinite linear 0.5s;
}

.dot-3 {
    -webkit-animation: animation-dots 1.5s infinite linear;
            animation: animation-dots 1.5s infinite linear;
}

@-webkit-keyframes animation-dots {
    0% {
        left: -150px;
        opacity: 0;
    }
    20% {
        left: 0px;
        opacity: 1;
    }
    80% {
        left: 100px;
        opacity: 1;
    }
    100% {
        opacity: 0;
        left: 200px;
    }
}

@keyframes animation-dots {
    0% {
        left: -150px;
        opacity: 0;
    }
    20% {
        left: 0px;
        opacity: 1;
    }
    80% {
        left: 100px;
        opacity: 1;
    }
    100% {
        opacity: 0;
        left: 200px;
    }
}

@media only screen and (max-width: 991px) {
    .secondBlockSty {
        padding: 40px 0px 40px;
    }
    .AddPaddingContactForm {
        padding: 40px 10px;
    }
}

@media only screen and (max-width: 384px) {
    .ModalHeaddingText {
        font-size: 25px;
    }
    .modal-body {
        padding: 12px;
    }
}

@media only screen and (max-width: 320px) {
    .ModalHeaddingText {
        font-size: 25px;
    }
    .modal-body {
        padding: 12px;
    }
    .ModalContactHeaddingText {
        font-size: 24px;
    }
}

@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
        .ModalFaqs .closeIconModale {
            width: 25px;
            height: 25px;
            font-size: 29px;
            border-radius: 50% !important;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #191C1A33;
            color: #26331fe0;
        }
    }
}
.loader {
    display: inline-block;
    width: 1em;
    height: 1em;
    color: inherit;
    vertical-align: middle;
    pointer-events: none;
}

.loader {
    border-radius: 50%;
    box-shadow: 0 1em 0 -0.2em currentcolor;
    position: fixed;
    -webkit-animation: loader-14 0.8s ease-in-out alternate infinite;
    animation: loader-14 0.8s ease-in-out alternate infinite;
    -webkit-animation-delay: 0.32s;
    animation-delay: 0.32s;
    top: 50%;
    left: 50%;
}

.loader:before {
    content: "";
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    box-shadow: inherit;
    -webkit-animation: inherit;
    animation: inherit;
}

.loader:before {
    left: -1em;
    -webkit-animation-delay: 0.48s;
    animation-delay: 0.48s;
}

.loader:after {
    content: "";
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    box-shadow: inherit;
    -webkit-animation: inherit;
    animation: inherit;
}

.loader:after {
    right: -1em;
    -webkit-animation-delay: 0.16s;
    animation-delay: 0.16s;
}

@-webkit-keyframes loader-14 {
    0% {
        box-shadow: 0 2em 0 -.2em currentcolor;
    }
    100% {
        box-shadow: 0 1em 0 -.2em currentcolor;
    }
}

@keyframes loader-14 {
    0% {
        box-shadow: 0 2em 0 -.2em currentcolor;
    }
    100% {
        box-shadow: 0 1em 0 -.2em currentcolor;
    }
}
