/* 8th */

.ready_margin {
    padding: 60px 8px;
}

.HeadingGetStart {
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    color: #26331fe0;
    text-align: center;
    padding-top: 40px;
}

.ParaGetStart {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    max-width: 758px;
    line-height: 24px;
    text-align: center;
    color: rgba(27, 27, 30, 0.8);
    padding-top: 20px;
    margin: 0px auto;
}

.WhiteButtonGetStart {
    padding: 20.5px 40px;
    /* border: 1px solid #85BB65; */
    /* background: #85BB65; */
    font-size: 15px;
    color: #ffffff;
    font-weight: 500;
    border-radius: 6px;
    /* box-shadow: 0px 3px 8px rgba(25, 28, 26, 0.24); */
}

.WhiteButtonGetStart:hover {
    background: #0B622D;
    color: #ffffff;
}

.steps_text1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    /* color: #2d2926; */
    margin-bottom: 12px;
}

.steps_text1_mob {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 15px;
    display: flex;
    align-items: center;
    /* color: #85BB65; */
    padding: 0px 15px;
}

.GetstartButtonContainer {
    text-align: center;
    margin: auto;
    padding-top: 60px;
    /* padding-bottom: 80px; */
}

@media only screen and (max-width: 991px) {

    /* Ready to request */

    .requeststeps {
        margin: 42px 0px 20px;
        display: flex;
    }

    .BoxPara {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .GetstartButton_request {
        padding-bottom: 60px;
    }

}

@media only screen and (max-width: 767px) {


    /* ready  */
    .ready_margin {
        padding: 40px 8px;
    }

    .GetstartButtonContainer_mob {
        padding-top: 80px;
        text-align: center;
    }

    .all_blcok_head_request {
        margin-bottom: 0px;
    }

    .HeadingGetStart {
        font-size: 32px;
        font-weight: 700;
        line-height: 39px;
        text-align: center;
        padding-top: 40px;
    }

    .ParaGetStart {
        padding-top: 20px;
        padding-bottom: 0px;
        margin-bottom: 0px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: rgba(27, 27, 30, 0.8);
    }

    .ParaGetStart {
        width: 100%;
    }

    .WhiteButtonGetStart {
        padding: 16.5px 32px;
        font-size: 16px;
        margin-top: 10px;
    }

    .GetstartButtonContainer {
        padding: 40px 0px 0px;
    }

}



/* 8th

.requestmoneyblock {
    background: #FFFFFF;
}
.top_ready {
    padding-top: 48px;
}
.ParaGetStart {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: rgba(27, 27, 30, 0.8);
    padding-top: 20px;
}
.steps_text1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #4D45D4;
    padding: 20px 45px;
}

.steps_text1_mob {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #4D45D4;
    padding: 0px 15px;      
}

@media only screen and (max-width: 1024px){
    .ParaGetStart {
        font-size: 16px;
        padding-top: 20px;
        text-align: center;
    }
    .top_ready {
        padding-top: 48px;
    }
    
} */