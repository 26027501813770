/* 9th footer Block */

.FooterBlockContainer {
  padding: 60px 0px 60px 0px;
}

.FooterLogoSty {
  font-size: 40px;
  font-weight: 600;
  color: #FFFFFF;
}

.FooterLogoPara {
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFFCC;
  line-height: 19.5px;
  margin-top: 12px;
  width: 40%;
}

.FooterLink {
  padding-top: 22px;
}

.FooterLink p {
  color: #FFFFFF99;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 18px;
}

.FooterPara {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: #FFFFFF99;
  margin-bottom: 0px;
  padding: 78px 0px 0px;
}

.OlaImgsty {
  padding-top: 64px;
}

.bolck_bg_footer {
  background: #26331f;
}

.logoCenterText2_footer {
  font-weight: 600;
  font-size: 24px;
  line-height: 0px;
  color: #85BB65 !important;
  padding-right: 8px;
  width: 30%;
}

.logoCenterTextFooter {
  color: white;
}

.FooterLink a {
  cursor: pointer;
}

.FooterLink p:hover {
  color: #FFFFFF;
  font-weight: 600;
  text-decoration: underline;
}
.FooterFaqs_main .accordion-item{
max-width: 100%;
}
.FooterFaqs_main .FaqBlockContainer .accordion {
  padding-top: 0px;
}
.ola_logo{
  padding-top: 20px;
  width: 50px;
}

@media only screen and (max-width: 991px) {
  .imgStyFooterlogo {
    width: 40px;
    height: 40px;
    margin-right: 8px;
}
.logoCenterText2_footer {
  line-height: 28px;
}

.imgStyFooterlogo2 {
  /* width: 10.64%; */
  margin-right: 8px;
}

.FooterLink {
  padding-top: 0px;
}

.FooterBlockContainer {
  padding: 80px 20px 60px 20px;
}

.OlaImgsty {
  padding-top: 64px;
}

.FooterLogoPara {
  width: 195px;
}

.AddPaddingContactForm {
  padding: 40px 10px;
}
}
@media screen and (min-width: 500px) and (max-width: 767px) {

  .FooterLogoPara {
    width: 279px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    margin-bottom: 0px;
}

.FooterLink {
    padding-top: 60px;
}

.FooterLink p {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 16px;
}

.FooterPara {
    padding: 44px 0px 40px;
    text-align: justify;
}

.FooterBlockContainer {
    padding: 40px 8px;
}


.FooterLogoSty {
    font-size: 22px;
}

.imgStyFooterlogo {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.imgStyFooterlogo2 {
    /* width: 24px;
    height: 24px; */
    margin-right: 8px;
}


.logoCenterText2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    padding: 16px 0px 16px;
    display: flex;
    align-items: center;
}

.logoCenterText2_footer {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    padding: 0px 0px 16px;
}
.FooterPara {
  
  text-align: justify;
}
}
@media screen and (min-width: 320px) and (max-width: 500px) {
  .FooterLink p {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 16px;
}
.ola_logo{
  padding-top: 10px;
  padding-bottom: 10px;
  width: 50px;
}
.FooterPara {
  
  text-align: justify;
}
}