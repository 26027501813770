/* --------------REQUEST DESKTOP------------------- */

.addPaddingBlock {
    padding: 80px 20px;
}

.GetstartButtonContainer_step {
    display: flex;
    align-items: center;
    justify-content: center;
}

.saprater {
    width: 100%;
    height: 2px;
    /* background: #191C1A4D */
}

.Display_nuber_Img {
    display: flex;
    align-items: center;
    margin-right: 19rem;
    margin-left: 5rem;
    padding-top: 80px;
}

.BoxParentStyle {
    padding-top: 80px;
    padding-bottom: 80px;
}

.BoxHeadding {
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    /* color: #26331fe0; */
    padding-top: 28px;
}

.BoxPara {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    /* color: #191C1ACC; */
    padding-top: 12px;
    margin-right: 0rem;
    margin-bottom: 0px;
}

.SingleBoxInnerCol5th {
    /* padding: 40px 32px; */
    margin: 0px 40px;
    height: 100%;
}

.MobilResponsiveDisplay2 {
    display: none;
}

.requesticon_bg {
    /* background: #FFFFFF; */
    /* box-shadow: 0px 0px 12px 2px #85bb651f; */
    border-radius: 10px;
    padding: 13.5px 12px;
    margin-top: 12px;
}

.steps_text1 {
    margin-bottom: 20px !important;
    margin-top: 24px;
}

.steps_text1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    /* color: #85BB65; */
    margin-bottom: 20px;
}

.steps_text1_mob {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 15px;
    display: flex;
    align-items: center;
    /* color: #85BB65; */
    padding: 0px 15px;
}

.GetstartButtonContainer {
    text-align: center;
    margin: auto;
    padding-top: 60px;
}

@media only screen and (max-width: 991px) {
    .all_blcok_head {
        text-align: center;
        margin-bottom: 60px;
        /* color: #26331fe0; */
    }
    /* request */
    .StyleMob5th {
        flex-direction: column !important;
    }
    .saprater {
        width: 2px;
        height: 150px;
        /* background: #191C1A00; */
    }
    .Main_Div_Number_Img {
        padding-bottom: 0px;
        padding-right: 20px;
    }
    .Display_nuber_Img {
        flex-direction: column;
        padding-top: 0px;
        margin-right: 1rem;
        margin-left: 0rem;
    }
    .MobilResponsiveDisplay1 {
        display: none;
    }
    .MobilResponsiveDisplay2 {
        display: block;
    }
    .seperator2 {
        height: 150px;
    }
    .all_blcok_head_request {
        margin-bottom: 0px;
    }
    .bolck_bg {
        /* background: #f9fffb; */
    }
    .AddmarginMobileRes {
        margin-bottom: 30px;
    }
    .BoxParentStyle {
        padding-top: 0px;
        padding-bottom: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .all_block_headRequestMob {
        margin-bottom: 12px;
    }
    .MobileImgWidth {
        width: 24px;
        height: 24px;
    }
    .Display_nuber_Img {
        margin-right: 0rem;
        margin-left: 0rem;
        padding-top: 36px;
    }
    .seperator2 {
        height: 156px;
    }
    /* request */
    .Main_Div_Number_Img {
        padding-bottom: 0px;
        margin-right: 20px;
    }
    .BoxHeadding {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        margin-top: 28px;
        padding-top: 0px;
    }
    .BoxParentStyle {
        padding-top: 40px;
        padding-bottom: 0px;
    }
    .requesticon_bg {
        /* background: #FFFFFF; */
        box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.12);
        border-radius: 10px;
        padding: 8px;
        margin-top: 12px;
    }
    .magin_three_img {
        padding: 0px 0px;
    }
    .saprater {
        height: 160px;
    }
}

@media only screen and (max-width: 370px) {
    .seperator2 {
        height: 156px;
    }
}

@media only screen and (max-width: 353px) {
    .saprater {
        width: 2px;
        height: 160px;
    }
    .seperator2 {
        height: 180px;
    }
}

@media only screen and (max-width: 333px) {
    .saprater {
        width: 2px;
        height: 175px;
    }
}

@media only screen and (max-width: 331px) {
    .saprater {
        width: 2px;
        height: 205px;
    }
}