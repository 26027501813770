body {
    font-family: 'Inter' !important;
    font-size: 18px;
}

* {
    box-sizing: border-box;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0rem;
}

a {
    text-decoration: none;
}


/* comman  Mar */

.form_block.navBg {
    position: relative !important;
}

.navbar.form_block>.container {
    justify-content: center;
}

.form_block .logoCenterText2 {
    justify-content: center;
}

.form_block .imgStyFooterlogo {
    width: 100%;
}

.all_blcok_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    /* color: #26331fe0; */
}

.cus_sty_getstart {
    margin-left: 12px;
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
    .col-lg-6 {
        padding: 0px !important;
    }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
    .container {
        max-width: 500px;
    }
}

@media only screen and (min-width: 1440px) {
    .navbarMargin {
        padding-left: 19rem;
        padding-right: 19rem;
    }
    .logoCenterText2 {
        display: flex;
    }
    .DisplayNoneFooterlogo {
        display: block;
    }
    .imgArrowDisplymob {
        display: none;
    }
    .imgArrowDisplydesk {
        display: contents;
        justify-content: space-between;
    }
    .LinkDisplayShowNot {
        display: none;
    }
    .ArrowIconParent .icon {
        fill: #26331fe0;
    }
}

@media only screen and (min-width: 2560px) {
    .navbarMargin {
        padding-left: 39rem;
        padding-right: 39rem;
    }
    /* .MobilResponsiveDisplay2 {
        display: none;
    } */
    .logoDisplay1 {
        display: block;
    }
    .logoDisplay2 {
        display: none;
    }
    .DisplayNoneFooterlogo {
        display: block;
    }
    .LinkDisplayShowNot {
        display: none;
    }
    .amount_but {
        padding: 18px 20px;
    }
}

@media only screen and (max-width: 1440px) {
    .navbarMargin {
        padding-left: 5rem;
        padding-right: 5.5rem;
    }
    .logoDisplay2 {
        display: block;
    }
    .imgArrowDisplymob {
        display: none;
    }
    .imgArrowDisplydesk {
        display: block;
    }
    .LinkDisplayShowNot {
        display: none;
    }
}

@media only screen and (max-width: 1200px) {
    .logoCenterText2 {
        font-size: 24px;
        display: flex;
        width: 24%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .BannerImgFirstBlock {
        padding-top: 34%;
    }
}

@media only screen and (max-width: 1024px) {
    .navbarMargin {
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .imgArrowDisplymob {
        display: none;
    }
    .imgArrowDisplydesk {
        display: block;
    }
    .LinkDisplayShowNot {
        display: none;
    }
    .logoDisplay1 {
        display: none;
    }
    .logoDisplay2 {
        display: block;
        padding: 14px 0px 0px;
    }
    .NavLinkStyle li a {
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
        color: #191C1Acc !important;
    }
}

@media only screen and (max-width: 320px) {
    /* .AddPadingCircleArrow a {
        font-size: 14px;
    } */
    .offcanvas-collapse {
        top: 48px !important;
    }
    .AddPadingCircleArrow {
        padding: 0px 0px 0px 0px !important;
    }
    .ModalHeaddingText {
        font-size: 25px;
    }
    .modal-body {
        padding: 12px;
    }
    .ModalContactHeaddingText {
        font-size: 24px;
    }
}

.get_start_btn {
    padding: 1rem 1.5rem;
    /* border: 1px solid #85BB65; */
    /* background: #85BB65; */
    font-size: 16px;
    /* color: #ffffff; */
    font-weight: 600;
    border-radius: 6px;
    line-height: 24px;
    font-family: 'Inter';
}

.get_start_btn:hover {
    background: #0B622D;
    color: #ffffff;
}

.amount_but:hover {
    border: 1px solid #85BB65;
    /* margin-bottom: -4px; */
}

.amount_but {
    padding: 16px;
    width: 100%;
    /* background: #FFFFFF; */
    border: 0.5px solid #191C1A33;
    border-radius: 6px;
    color: #26331fe0;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    height: 53px;
}

.ArrowIconParent {
    /* border: 1px solid #85BB65; */
    /* background: #85BB65; */
    border-radius: 6px;
    box-shadow: 0px 3px 8px rgba(25, 28, 26, 0.24);
    padding: 18.5px 32px;
    display: flex;
    align-items: center;
}

.ArrowIconParentbanner {
    /* border: 1px solid #85BB65; */
    /* background: #85BB65; */
    border-radius: 6px;
    width: 100%;
    max-width: 376px;
    padding: 14px 32px;
    align-items: center;
}

.buttonText {
    display: flex;
    justify-content: space-between;
}

.AddPadingCircleArrow a {
    font-size: 16px;
    letter-spacing: 1px;
    font-family: 'Inter';
    font-weight: 500 !important;
    line-height: 19px;
    color: #ffffff !important;
}

.ArrowIconParent:hover {
    color: #ffffff;
    background: #0B622D;
}

.WhiteButtonGetStart {
    padding: 20.5px 40px;
    /* border: 1px solid #85BB65; */
    /* background: #85BB65; */
    font-size: 15px;
    /* color: #ffffff; */
    font-weight: 500;
    border-radius: 6px;
    /* box-shadow: 0px 3px 8px rgba(25, 28, 26, 0.24); */
}

@media only screen and (max-width: 991px) {
    .get_start_btn {
        line-height: 24px;
        padding: 16px 40px;
        /* border: 1px solid #85BB65; */
        /* background: #85BB65;*/
        font-size: 20px;
        margin: 0px auto;
        color: #ffffff;
        border-radius: 6px;
    }
    .AddPadingCircleArrow a {
        font-family: 'Inter';
        font-weight: 500 !important;
        line-height: 19px;
        color: #ffffff !important;
    }
    .all_blcok_head {
        font-weight: 600;
        font-size: 48px;
        line-height: 58px;
        text-align: center;
        margin-bottom: 60px;
        /* color: #26331fe0; */
    }
    .AddMargin {
        padding: 20px 0px;
    }
    .AddmarginMobileRes {
        margin-bottom: 30px;
    }
    .Get_btn_Mobile {
        background: #ffffffe0;
        padding: 16px 40px;
        /* border: 1px solid #85BB65; */
        /* background: #85BB65; */
        font-size: 20px;
        /* color: #ffffff; */
        font-weight: 500;
        border-radius: 6px;
        line-height: 24px;
    }
    .imgArrowDisplymob {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .imgArrowDisplydesk {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .addpaddingBolock {
        padding-top: 0px;
    }
    .get_start_btn {
        padding: 20px 40px;
        /* border: 1px solid #85BB65; */
        /* background: #85BB65; */
        font-size: 16px;
        color: #ffffff;
        border-radius: 6px;
        margin: 0px auto;
        font-weight: 600;
        line-height: 24px;
    }
    .add_padding_top_sub_blc {
        padding: 0px;
    }
    .amount_but {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        padding: 16px;
        border: 1px solid #191C1A1F;
    }
    .all_blcok_head {
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 40px;
    }
    .ArrowIconParentbanner {
        width: 100%;
    }
}

@media only screen and (min-width: 320px) and (max-width: 500px) {
    .all_blcok_head {
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 40px;
    }
}

.field dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 0.5rem !important;
}

.FormHeaderSty {
    justify-content: center !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
}

input::-moz-placeholder {
    opacity: 0 !important;
}

.firstBlock_Text_parent input:focus::-moz-placeholder {
    opacity: 0.4 !important;
}


/* comman-------------=============

.heyy_ra {
    background-color: #85BB65;
}

.offcanvas.offcanvas-end {
    top: 70px;
    width: 100%;
}

body {
    font-family: 'Inter' !important;
    font-size: 18px;
}

* {
    box-sizing: border-box;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0rem;
}

a {
    text-decoration: none;
}

.cus_sty_getstart {
    margin-left: 12px;
}

.row {
    margin-right: calc(var(--bs-gutter-x) * -0);
    margin-left: calc(var(--bs-gutter-x) * -0);
}

.parent_container .container {
    padding-right: var(--bs-gutter-x, 20px) !important;
    padding-left: var(--bs-gutter-x, 20px) !important;
}

.parent_container .second_block_parent_margin {
    margin-left: -17px;
    margin-right: -17px;
    padding-top: 0px;
}

.all_blcok_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #1b1b1e;
}

.GetstartButtonContainer_step {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.WhiteButtonGetStart {
    padding: 16px 24px;
    border: 1px solid #FAD419;
    background: #FAD419;
    font-size: 15px;
    color: #1B1B1E;
    font-weight: 500;
    border-radius: 4px;
}

.WhiteButtonGetStart:hover {
    background: #F2BD00;
    box-shadow: 0px 3px 8px rgb(25 28 26 / 24%);
    color: #1B1B1E;
}

.GetstartButtonContainer {
    text-align: center;
    margin: auto;
    padding-top: 40px;
    padding-bottom: 80px;
} */


/* ------------Modal Final-------------- */


/* ===========================================Modal Css========================================= */

.ModalHeaddingText {
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    color: #26331fe0;
    margin: 0px;
    padding-bottom: 20px;
}

.closeIconModale {
    width: 25px;
    height: 25px;
    font-size: 19px;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #191C1A33;
    color: #26331fe0;
    background: #efefef;
}

.modelpara {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: #191C1ACC;
}

.faqbtnSty {
    border: 0px;
    background: none !important;
    padding: 0px;
}

.closeBtnSty {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: #26331fe0;
    padding: 10px 20px;
    border: 1px solid #26331fe0;
    border-radius: 6px;
}

.closeBtnSty:hover {
    color: #26331fe0;
    background: rgba(62, 54, 159, 0.08);
}

.mainParaSty {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: #26331fe0;
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 40px 40px 4px 40px;
    border-bottom: none;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 40px 40px;
}

.modal-footer {
    padding: 80px 0px 0px 0px;
    border-top: none;
}

.ModalFaqs .accordion-button:not(.collapsed) {
    color: #0c63e4;
    background-color: #e7f1ff;
}

.ModalFaqs .accordion-button:focus {
    z-index: 3;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
}

.ModalFaqs .accordion-button:not(.collapsed) {
    color: #0c63e4;
    background-color: #e7f1ff;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 0%);
}

.ModalFaqs .accordion-item {
    background-color: #fff;
    border: none;
    margin-top: 15px;
}

.ModalFaqs .accordion-button::after {
    content: none;
}

.ModalFaqs .modelpara {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #191C1ACC;
}

.borderAdd {
    border-bottom: 1px solid;
    text-align: start;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #26331fe0;
}

.modal {
    overflow-x: hidden;
    overflow-y: hidden;
}

.modalinputPadding {
    padding: 15px !important;
    border: none;
    background: #f3f3f3;
    border-radius: 12px;
    border: 2px solid #f3f3f3;
}

.twoInputStyFlName {
    margin-bottom: 34px;
    margin: 10px 10px;
}

.modal-body {
    max-height: calc(100vh - 10px);
    overflow-y: auto;
}

.btnSubscribSty {
    background: #85BB65;
    padding: 5px;
    color: #fff;
    border-radius: 5px;
    border: 1px solid #85BB65;
}

.ModalHeaddingSty {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.btnSubmitSty {
    background: #85BB65;
    border: 1px solid #85BB65;
    border-radius: 6px;
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    padding: 15px !important;
    border: none;
    cursor: pointer;
}


/* Contact us Model Css */

.addFormMargin .form-control:hover {
    background: #ffffff;
    border: 2px solid #191C1A4d;
    box-shadow: 0px 3px 20px rgba(62, 54, 159, 0.12);
    border-radius: 12px;
}

.addFormMargin .form-control:focus {
    background: #ffffff;
    border: 2px solid #26331fe0;
    box-shadow: 0px 3px 20px rgba(62, 54, 159, 0.12);
    border-radius: 12px;
}

.addFormMargin .form-control:focus-visible {
    background: #ffffff;
    border: 2px solid #26331fe0;
    box-shadow: 0px 3px 20px rgba(62, 54, 159, 0.12);
    border-radius: 12px;
    outline: none;
}


/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #f3f3f3 inset;
    transition: background-color 5000s ease-in-out 0s;
}

.ModalContactHeaddingText {
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    color: #26331fe0;
    margin: 0px;
}

.closeIconModaleContact {
    width: 25px;
    height: 25px;
    font-size: 19px;
    text-align: center;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #191C1A33;
    color: #26331fe0;
    margin-top: 0px;
    margin-right: 0px;
}

.ModalFaqs .modelparaContact {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #191C1ACC;
}

.ModalFaqsContact {
    color: #26331fe0;
    font-weight: 700;
}

.ModalFaqsContact:hover {
    color: #26331fe0;
    font-weight: 700;
}

.AddPaddingContactForm {
    padding: 40px 90px;
}

.inputLableSty {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin: 12px 0px 8px 4px;
}

html {
    scroll-behavior: smooth;
}

#inputselect {
    width: 100%;
    scroll-margin-block-start: 110px;
    scroll-margin-block-end: 110px;
}

.imgStyFooterlogo {
    width: 10.64%;
    margin-right: 8px;
}

.imgStyFooterlogo2 {
    margin-right: 8px;
}

.FaqBoxHeadding {
    /* font-size: 20px; */
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0px;
}


/* contact Model css */

#addressPlaceholder {
    padding-top: 30px;
}

#addressPlaceholder,
#phonePlaceholder,
#emailPlaceholder {
    text-align: center;
    margin: 15px auto;
    color: #1b1b1e;
    font-weight: 700;
}

#contactText {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #1b1b1eCC;
    text-align: center;
}

.error_color {
    width: 100%;
    list-style-type: none;
    font-size: 12px;
    line-height: 20px;
    height: 20px;
    font-weight: 500;
    line-height: 17px;
    color: #ff2b2b;
    border-top: none;
    border-radius: 0 0 4px 4px;
    padding: 0px 10px;
    text-align: start;
    transition: all 0.2s ease;
    margin-bottom: 0px;
}


/*  */

.main-div {
    height: auto;
    min-height: 100vh;
    width: 100%;
}

.dots {
    position: relative;
    width: 150px;
    height: 50px;
    transform: scale(0.25, 0.25);
}

.dot {
    width: 53px;
    height: 53px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    opacity: 0;
    border-radius: 50%;
    background-color: #EB275F;
}

.dot-1 {
    animation: animation-dots 1.5s infinite linear 1s;
}

.dot-2 {
    animation: animation-dots 1.5s infinite linear 0.5s;
}

.dot-3 {
    animation: animation-dots 1.5s infinite linear;
}

@-moz-keyframes animation-dots {
    0% {
        left: -150px;
        opacity: 0;
    }
    20% {
        left: 0px;
        opacity: 1;
    }
    80% {
        left: 100px;
        opacity: 1;
    }
    100% {
        opacity: 0;
        left: 200px;
    }
}

@-webkit-keyframes animation-dots {
    0% {
        left: -150px;
        opacity: 0;
    }
    20% {
        left: 0px;
        opacity: 1;
    }
    80% {
        left: 100px;
        opacity: 1;
    }
    100% {
        opacity: 0;
        left: 200px;
    }
}

@-o-keyframes animation-dots {
    0% {
        left: -150px;
        opacity: 0;
    }
    20% {
        left: 0px;
        opacity: 1;
    }
    80% {
        left: 100px;
        opacity: 1;
    }
    100% {
        opacity: 0;
        left: 200px;
    }
}

@keyframes animation-dots {
    0% {
        left: -150px;
        opacity: 0;
    }
    20% {
        left: 0px;
        opacity: 1;
    }
    80% {
        left: 100px;
        opacity: 1;
    }
    100% {
        opacity: 0;
        left: 200px;
    }
}

@media only screen and (max-width: 991px) {
    .secondBlockSty {
        padding: 40px 0px 40px;
    }
    .AddPaddingContactForm {
        padding: 40px 10px;
    }
}

@media only screen and (max-width: 384px) {
    .ModalHeaddingText {
        font-size: 25px;
    }
    .modal-body {
        padding: 12px;
    }
}

@media only screen and (max-width: 320px) {
    .ModalHeaddingText {
        font-size: 25px;
    }
    .modal-body {
        padding: 12px;
    }
    .ModalContactHeaddingText {
        font-size: 24px;
    }
}

@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
        .ModalFaqs .closeIconModale {
            width: 25px;
            height: 25px;
            font-size: 29px;
            border-radius: 50% !important;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #191C1A33;
            color: #26331fe0;
        }
    }
}