/* ----------------WHY US------------------- */
.add_padding_top_sub_blc {
    padding: 0px 0px 60px;
}

.sequer_main {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin: 0;
    color: #26331fe0;
    padding-top: 16px;
}

.sequer_sub {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    margin: 0;
    text-align: center;
    padding-top: 2px;
    color: #26331fe0;
}

.secondBlockSty {
    background: #FFFFFF;
    padding: 80px 0px;
}

.AddMargin {
    padding: 20px;
    text-align: center;
}

.whyus_icon {
    width: 40px;
}

@media only screen and (max-width: 991px) {
    /* second block */

    .secondBlockSty {
        padding: 40px 0px 40px;
    }
}

@media only screen and (max-width: 767px) {

    /* whyus  */
    .sequer_sub {
        font-size: 14px;
        line-height: 16px;
    }

    .sequer_main {
        font-size: 16px;
        line-height: 19px;
        padding-top: 20px;
    }

    .BoxPara {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        margin-right: 0rem;
        padding-top: 8px;
    }

    .whyus_icon {
        width: 32px;
    }

    .AddMargin {
        margin: 0rem;
    }
      .addPaddingBlockFrist .second_block_parent_margin .container {
        padding-right: var(--bs-gutter-x, .0rem) !important;
        padding-left: var(--bs-gutter-x, .0rem) !important;
    }
}

/* 
.second_block_parent_margin{
 background-color: #fafaff;
}
.sequer_main {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin: 0;
    color: #1b1b1e;
    padding-top: 16px;
}

.sequer_sub {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    text-align: center;
    padding-top: 2px;
    color: #1b1b1e;
}

.secondBlockSty {
    padding-bottom: 40px;
}

.secondBlockSty {
    background: #FFFFFF;
    border-radius: 8px;
}

.AddMargin {
    padding: 20px;
    text-align: center;
}

.add_padding_top_sub_blc {
    padding: 40px 0px;
} */