.parent_container .container {
    padding-right: var(--bs-gutter-x, 20px) !important;
    padding-left: var(--bs-gutter-x, 20px) !important;
}

.parent_container .second_block_parent_margin {
    padding-top: 0px;
    background: white;
}


/* -----------------banner 1------------------- */

.homeimg1 {
    display: block;
}

.homeimg2 {
    display: none;
}

.addPaddingBlockFrist {
    padding-top: 40px;
}

.firstBlock_Text {
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
    color: #26331fe0;
    margin-top: 80px;
}

.BannerImgFirstBlock {
    text-align: end;
    margin-top: 4rem;
}

.AmountColor {
    color: #85BB65;
}

.firstBlock_para_text {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    color: #191C1ACC;
    padding-bottom: 30px;
    padding-top: 20px;
    max-width: 600px;
    margin-bottom: 0px;
}

.firstBlock_Text_parent input:focus {
    outline: none !important;
    border-color: #85BB65;
    border-radius: 6px;
}

.firstBlock_Text_parent ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #191C1AE0;
    /* Firefox */
}

.firstBlock_Text_parent :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #191C1AE0;
}

.firstBlock_Text_parent ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #191C1AE0;
}

.res_width {
    text-align: center;
}

.AddPadingFirstBlockLink {
    padding-top: 30px;
    padding-bottom: 12px;
    max-width: 374px;
    text-align: center;
    margin: 0px auto;
}

.req_text {
    color: #465364;
}

.millin_text {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #46536499;
    text-align: center;
    padding-top: 24px;
    padding-bottom: 64px;
}

.million {
    color: #85BB65;
    font-weight: 700;
}

.firstBlock_subpara_text {
    font-size: 12px;
    font-weight: 500;
    margin-right: 17rem;
    color: #46536499;
    text-align: center;
    margin: auto;
    line-height: 14px;
}

.firstBlock_subpara_text a {
    /* color: #85BB65 !important; */
    font-weight: 700 !important;
    cursor: pointer;
}

.formalign_row {
    padding: 0px 58px;
}

@media only screen and (max-width: 991px) {
    .addPaddingBlockFrist {
        padding-top: 60px;
        text-align: center;
        margin: 0px auto;
    }
    .firstBlock_Text {
        font-size: 48px;
        text-align: center;
        line-height: 58px;
        font-weight: 700;
        max-width: none;
        margin-top: 75px;
    }
    .firstBlock_para_text {
        font-size: 20px;
        line-height: 24px;
        margin-right: 0rem;
        max-width: 600px;
        margin: 0px auto;
        padding-bottom: 40px;
        font-weight: 400;
        text-align: center;
    }
    .firstBlock_subpara_text {
        padding-top: 1rem;
        font-weight: 600;
        font-size: 12px;
        max-width: 374px;
    }
    .AddPadingFirstBlockLink {
        padding-top: 30px;
        padding-bottom: 12px;
    }
    .BoxParentStyle {
        padding-top: 0px;
        padding-bottom: 10px;
    }
    .GetstartButtonContainer {
        padding-top: 20px;
        padding-bottom: 0px;
    }
    .BannerImgFirstBlock {
        text-align: center;
        margin-top: 0rem;
    }
    .imgBannetSecondBlock {
        text-align: center;
    }
    .millin_text {
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
    .BoxHeadding {
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
        color: #26331fe0;
        padding-top: 28px;
    }
}

@media only screen and (max-width: 767px) {
    .formalign_row {
        padding: 0px 12px;
    }
    .accordion-button::after {
        width: 1rem;
        height: 1rem;
    }
    .res_width {
        width: 100%;
    }
    .ArrowIconParent {
        padding: 16px 32px;
        height: 56px;
        display: flex;
        align-items: center;
    }
    .BannerImgFirstBlock {
        padding-top: 0px;
    }
    /* .homeimg1 {
        display: none;
    } */
    .homeimg2 {
        display: block;
    }
    .form-inline {
        padding-top: 55px;
        text-align: center;
    }
    .AddPadingCircleArrow {
        padding: 0px 0px 0px 0px !important;
    }
    /* nav  */
    .Get_btn_Mobile {
        background: #ffffffe0;
        padding: 20px 40px;
        border: 1px solid #85BB65;
        background: #85BB65;
        font-size: 16px;
        color: #ffffff;
        font-weight: 500;
        border-radius: 6px;
        line-height: 24px;
    }
    .imgArrowDisplydesk {
        display: none;
    }
    .ResponsivFristBlock.addPaddingBlockFrist {
        padding-top: 0px;
    }
    .addPaddingBlockFrist .second_block_parent_margin .container {
        padding-right: var(--bs-gutter-x, .0rem) !important;
        padding-left: var(--bs-gutter-x, .0rem) !important;
    }
    .firstBlock_Text {
        font-size: 32px;
        font-weight: 700;
        max-width: 374px;
        margin: 32px auto 0px;
        line-height: 40px;
    }
    .firstBlock_para_text {
        color: #191C1ACC;
        padding-top: 12px;
        padding-bottom: 30px;
        max-width: 344px;
        margin: 0px auto;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
    }
    .ArrowIconParentbanner {
        border: 1px solid #85BB65;
        background: #85BB65;
        border-radius: 6px;
        font-size: 16px;
        width: 92.5%;
        line-height: 19px;
        /* box-shadow: 0px 3px 8px rgb(25 28 26 / 24%); */
        padding: 16.5px 32px;
        align-items: center;
    }
    .AddPadingFirstBlockLink {
        padding-top: 8px;
        padding-bottom: 16px;
    }
    .millin_text {
        font-size: 14px;
        line-height: 17px;
        padding-top: 16px;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
}

@media only screen and (max-width: 384px) {
    .firstBlock_Text {
        font-size: 32px;
        font-weight: 700;
        max-width: 90%;
    }
    .firstBlock_para_text {
        max-width: 90%;
        margin: 0px auto;
    }
    .firstBlock_subpara_text {
        padding-top: 1rem;
        font-weight: 600;
        font-size: 12px;
        max-width: 90%;
    }
    .ArrowIconParentbanner {
        width: 100%;
    }
}

@media only screen and (max-width: 320px) {
    .AddPadingCircleArrow {
        padding: 0px 0px 0px 0px !important;
    }
}


/* Header and first---------------- */


/* .navbar-nav .form-inline {
    margin-left: 60px;
}
.homeimg1 {
    display: block;
}

.homeimg2 {
    display: none;
}

.active {
    color: #1b1b1e !important;
}

.get_start_btn {
    padding: 1rem 1.5rem;
    border: 1px solid #FAD419;
    background: #FAD419;
    font-size: 16px;
    color: #1B1B1E;
    font-weight: 500;
    border-radius: 4px;
    line-height: 24px;
    font-family: 'Inter';
}

.get_start_btn:hover {
    background: #F2BD00;
    box-shadow: 0px 3px 8px rgb(25 28 26 / 24%);
    color: #1B1B1E;
}

.addPaddingBlockFrist {
    padding-top: 40px;
}

.amount_but:hover {
    border: 0.5px solid #4D45D4;
}

.firstBlock_Text {
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
    color: #1b1b1e;
}

.BannerImgFirstBlock {
    text-align: end;
}

.AmountColor {
    color: #4D45D4;
}

.firstBlock_para_text {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #1b1b1eCC;
    padding-top: 20px;
    margin-right: 13rem;
    margin-bottom: 0px;
}

.amount_but {
    padding: 12px 20px;
    width: 100%;
    background: #FFFFFF;
    border: 0.5px solid #1b1b1e33;
    border-radius: 8px;
    font-size: 16px;
    color: #1b1b1e;
    font-weight: 500;
    line-height: 29px;
}

.firstBlock_Text_parent input:focus {
    outline: none !important;
    border-color: #4D45D4;
    border-radius: 4px;
}

.firstBlock_Text_parent ::placeholder {

    color: #1b1b1eE0;

}

.firstBlock_Text_parent :-ms-input-placeholder {

    color: #1b1b1eE0;
}

.firstBlock_Text_parent ::-ms-input-placeholder {

    color: #1b1b1eE0;
}

.PaddingAmountbtn {
    padding-top: 40px;
}

.AddPadingFirstBlockLink {
    padding-top: 12px;
    padding-bottom: 16px;
}

.AddPadingCircleArrow {
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
}

.ArrowIconParent {
    border: 1px solid #FAD419;
    background: #FAD419;
    border-radius: 4px;
    padding: 18px 32px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.AddPadingCircleArrow a {
    font-size: 16px;
    letter-spacing: 1px;
    font-family: 'Inter';
    font-weight: 500 !important;
    line-height: 18px;
    color: #1B1B1E !important;
}

.ArrowIconParent:hover {
    color: #ffffff;
    background: #F2BD00;
    box-shadow: 0px 3px 8px rgb(25 28 26 / 24%);
}


.millin_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #46536499;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 16px;
}

.million {
    color: #4D45D4;
    font-weight: 700;
}

.firstBlock_subpara_text {
    font-size: 12px;
    font-weight: 500;
    margin-right: 17rem;
    color: #46536499;
    text-align: center;
    margin: auto;
    line-height: 19px;
}

.firstBlock_subpara_text a {
    color: #4D45D4 !important;
    font-weight: 700 !important;
    cursor: pointer;
}

@media only screen and (max-width: 1440px) {


    .imgArrowDisplymob {
        display: none;
    }

    .imgArrowDisplydesk {
        display: block;
    }
}

@media only screen and (max-width: 1200px) {
    .ArrowIconParent:hover .icon {
        fill: #fff;
    }
}

@media only screen and (max-width: 1024px) {

    .ArrowIconParent:hover .icon {
        fill: #fff;
    }

    .firstBlock_Text {
        font-size: 2rem;
    }
}

@media only screen and (min-width: 2560px) {


    .amount_but {
        padding: 18px 20px;
    }
}
@media only screen and (min-width: 1440px) and (max-width: 2560px) {
   
    .imgArrowDisplymob {
        display: none;
    }
}

@media only screen and (min-width: 1440px) {
   
}

@media only screen and (max-width: 1440px) {
  

    .logoDisplay1 {
        display: block;
    }

    .logoDisplay2 {
        display: block;
    }
}



@media only screen and (max-width: 1024px) {
    .amount_but {
        font-size: 20px;
    }

    .logoCenterText2_footer {
        font-size: 24px;
    }

    .logoDisplay1 {
        display: none;
    }

    .logoDisplay2 {
        display: block;
        padding: 0px 0px 0px;
    }

    .get_start_btn {
        padding: 20px 40px;
        border: 1px solid #FAD419;
        background: #FAD419;
        font-size: 16px;
        color: #1B1B1E;
        font-weight: 500;
        border-radius: 4px;
        line-height: 24px;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 10px;
        padding-left: 10px;
    }
}

@media only screen and (max-width: 810px) {
  
    .get_start_btn {
        padding: 16px 40px;
        background: #ffffff;
        border: 2px solid #191C1A;
        font-size: 20px;
        color: #191C1A;
        border-radius: 4px;
        line-height: 24px;
    }

    .form-inline {
        padding-left: 2rem;
        margin: auto;
        padding-top: 20px;
        padding-bottom: 60px;
    }

}

@media only screen and (max-width: 991px) {
    .firstBlock_para_text {
        font-size: 16px;
        margin-right: 0rem;
        text-align: start;
        margin-right: 12rem;
    }

    .firstBlock_Text {
        text-align: start;
        line-height: 39px;
    }

    .ulstylink {
        box-shadow: 0px -1px 0px rgb(50 50 50 / 10%) !important;
    }

    .navbar-nav {
        width: 100%;
        padding-top: 40px;
        background: #f9fffb;
    }

    .logoDisplay1 {
        display: none;
    }

    .logoDisplay2 {
        display: block;
        padding: 0px 0px 0px;
    }

   

    .get_start_btn {
        padding: 16px 40px;
        background: #ffffff;
        border: 1px solid #4D45D4;
        background: #4D45D4;
        ;
        font-size: 20px;
        color: #ffffff;
        border-radius: 4px;
        line-height: 24px;
    }

    .form-inline {
        padding-top: 20px;
    }

 

    .form-inline {
        padding-top: 55px;
        text-align: center;
        margin: auto;
        height: 100vh;
    }

    /* Effect css  */


/* .navbar .button-wrap {
        text-align: center;
    }

    .navbar .button {
        position: relative;
        display: inline-block;
        margin: 1em;
        padding: 0;
        border: 0;
        background: none;
        color: #286aab;
        font-size: 1.2em;
        font-family: "arial black";
        transition: color 0.7s;
        cursor: pointer;
    }

    .navbar .button.button-click,
    .navbar .button:focus {
        outline: none;
        color: #3c8ddc;
    }

    .navbar .button:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -25px 0 0 -25px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        opacity: 0;
    }

    effect b---------------
    .navbar .button-effect-b:after {
        width: 50px;
        height: 50px;
        border: 10px solid rgba(111, 148, 182, 0.1);
    }

    .navbar .button-effect-b.button-click:after {
        -webkit-animation: anim-effect-b 0.3s forwards;
    }

    @-webkit-keyframes anim-effect-b {
        0% {
            -webkit-transform: scale3d(0.3, 0.3, 1);
        }

        25%,
        50% {
            opacity: 1;
        }

        100% {
            opacity: 0;
            -webkit-transform: scale3d(1.2, 1.2, 1);
        }
    } 

    Nav Link Effect-----------
    .ulstylink .nav-link {
        padding: 0px 0px;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding: 8px 20px;
        margin: 5px 0px;
        background: #FFFFFF;
        border-radius: 8px;
    }
}

@media only screen and (max-width: 768px) and (min-width:424px) {
    .firstBlock_Text {
        text-align: center;
        line-height: 39px;
    }
    .firstBlock_para_text {
        font-size: 16px;
        margin-right: 0rem;
        text-align: center;
    }
}
@media only screen and (max-width: 768px) {
    .imgStyFooterlogo {
        width: 25%;
        margin-right: 8px;
    }

    .firstBlock_Text {
        text-align: center;
        line-height: 39px;
    }

    /* .ulstylink {
        box-shadow: 0px -1px 0px rgb(50 50 50 / 10%) !important;
    } */


/* .navbar-nav {
        width: 100%;
        padding-top: 40px;
    } 
    .logoDisplay1 {
        display: none;
    }

    .logoDisplay2 {
        display: block;
        padding: 0px 0px 0px;
    }

    /* .navbarMargin {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .NavLinkStyle li a {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #191C1A !important;
        padding-top: 32px;
    } 

    .get_start_btn {
        padding: 16px 40px;
        border: 1px solid #4D45D4;
        background: #4D45D4;
        ;
        font-size: 20px;
        color: #ffffff;
        border-radius: 4px;
        line-height: 24px;
    }

    .form-inline {
        padding-top: 20px;
    }
 .ulstylink li {
        padding-left: 0rem;
    } 

    .form-inline {
        padding-top: 55px;
        text-align: center;
    }

    /* .nav_padding {
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 20px;
        margin-right: 20px;
    } */


/* Effect css  */


/* .navbar .button-wrap {
        text-align: center;
    } */


/* 
    .navbar .button {
        position: relative;
        display: inline-block;
        padding: 0;
        border: 0;
        background: none;
        color: #286aab;
        font-size: 1.2em;
        transition: color 0.7s;
        cursor: pointer;
        top: 1px;
    } */


/* .navbar .button.button-click,
    .navbar .button:focus {
        outline: none;
        color: #3c8ddc;
    }

    .navbar .button:after {
        content: "";
        position: absolute;
        top: 25%;
        left: 15%;
        margin: -20px 0 0 -20px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        opacity: 0;
    }

    effect b==============
    .navbar .button-effect-b:after {
        width: 55px;
        height: 48px;
        border: 10px solid rgba(111, 148, 182, 0.1);
    }

    .navbar .button-effect-b.button-click:after {
        -webkit-animation: anim-effect-b 0.3s forwards;
    }

    @-webkit-keyframes anim-effect-b {
        0% {
            -webkit-transform: scale3d(0.3, 0.3, 1);
        }

        25%,
        50% {
            opacity: 1;
        }

        100% {
            opacity: 0;
            -webkit-transform: scale3d(1.2, 1.2, 1);
        }
    }
}

@media only screen and (max-width: 767px) {
    .nav_padding {
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media only screen and (min-width: 767px) {
    .nav_padding {
        margin: auto;
    }
} 

@media only screen and (max-width: 500px) {
  
.AddTopPadding {
    padding-top: 52px;
}
    /* .imgStyFooterlogo {
        width: 40%;
        margin-right: 8px;
    } 

    .logoCenterText2 {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        padding: 0px 0px 16px;
        display: flex;
        align-items: center;
    }

    .logoCenterText2_footer {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        padding: 0px 0px 16px;
    }

    /* .bar1,
    .bar2,
    .bar3 {
        width: 16px;
        height: 2px;
        background-color: #4D45D4;
        margin: 4px 0;
        transition: 0.4s;
    } */


/* .navbar-brand {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 3px;
    } 

    .logoDisplay1 {
        display: none;
    }

    .get_start_btn {
        padding: 20px 40px;
        border: 1px solid #4D45D4;
        background: #4D45D4;
        ;
        font-size: 16px;
        color: #ffffff;
        border-radius: 4px;
        font-weight: 600;
        line-height: 24px;
    }
/* 
    .navbar-nav {
        width: 100%;
    }

    .navbarMargin {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .NavLinkStyle li a {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding-top: 32px;
    } 

    .form-inline {
        padding-top: 55px;
        text-align: center;
        padding-left: 6rem;
    }

    .ulstylink li {
        padding-left: 0rem;
        align-items: start;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding: 8px 20px;
    } 
}

@media only screen and (max-width: 428px) {
    .firstBlock_para_text {
        font-size: 16px;
        margin-right: 0rem;
        text-align: start;
        margin-right: 0rem;
    }
}

@media only screen and (max-width: 425px) {

    /* .bar1,
    .bar2,
    .bar3 {
        width: 16px;
        height: 2px;
        background-color: #4D45D4;
        margin: 4px 0;
        transition: 0.4s;
    } 
    .AddTopPadding {
        padding-top: 52px;
    }
    /* .navbar-brand {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 3px;
    }

    .logoDisplay1 {
        display: none;
    }

    .get_start_btn {
        padding: 20px 40px;
        border: 1px solid #4D45D4;
        background: #4D45D4;
        ;
        font-size: 16px;
        color: #ffffff;
        border-radius: 4px;
        font-weight: 600;
        line-height: 24px;
    }

    /* .navbar-nav {
        width: 100%;
    }

    .navbarMargin {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .NavLinkStyle li a {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding-top: 32px;
    } 

    .form-inline {
        padding-top: 55px;
        text-align: center;
    }

    .ulstylink li {
        padding-left: 0rem;
        align-items: start;
    }

    /* .navbar-expand-lg .navbar-nav .nav-link {
        padding: 8px 20px;
    } 

    .firstBlock_para_text {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        color: #1b1b1eCC;
        padding-top: 12px;
        margin-right: 0rem;
        margin-bottom: 0rem;
        text-align: center;
    }

    .imgStyFooterlogo {
        width: 40%;
        margin-right: 8px;
    }
}

@media only screen and (max-width: 384px) {
    .NavLinkStyle li a {
        font-size: 18px;
        line-height: 6px;
    }

    .form-inline {
        padding-top: 55px;
    }
}

@media only screen and (max-width: 375px) {
    .logoCenterText2 {
        font-size: 22px;
    }

    .logoDisplay1 {
        display: none;
    }

    /* .navbar-nav {
        width: 100%;
        padding-top: 40px;
       
    }

    .navbarMargin {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .logoCenterText2 {
        font-size: 18px !important;
    }

    /* .NavLinkStyle li a {
        font-size: 16px;
        line-height: 6px;
    } 

    .form-inline {
        padding-top: 55px;
        text-align: center;
    }
/* 
    .navbar-expand-lg .navbar-nav .nav-link {
        padding: 15px 20px;
        margin: 5px 0px;
    } 
}

@media only screen and (max-width: 320px) {
    .logoCenterText2 {
        font-size: 18px !important;
    }

    .firstBlock_Text {
        font-size: 1.3rem;
        line-height: 25px;
    }

    .get_start_btn {
        font-size: 16px;
    }
/* 
    .navbarMargin {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .logoCenterText2 {
        font-size: 18px !important;
    }

    .NavLinkStyle li a {
        font-size: 16px;
        line-height: 6px;
    } */


/* animation css 

@media (max-width: 991.98px) {
   .offcanvas-collapse {
        position: fixed;
        top: 85px;
        bottom: 0;
        right: -100%;
        width: 100%;
       
        visibility: hidden;
        background-color: #ffffff;
        border-top: 0.5px solid rgba(27, 27, 30, 0.16);
        transition: transform 0.6s ease-in-out, visibility 0.6s ease-in-out;
    } 
    /* .offcanvas-collapse.open {
        visibility: visible;
        transform: translateX(-100%);
    }

    .navbar-expand-lg .navbar-collapse {
        display: block !important;
        flex-basis: auto;
        visibility: visible;
        justify-content: start;
        /* background: #191C1A00; 
    } 

    .ulstylink {
        margin-right: 60px;
        align-items: start;
    }

    .ulstylink li {
        padding-left: 0rem;
        align-items: start;
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
    }

    .navbar-brand .form-inline {
        margin: auto !important;
    }

    .navbar-nav .form-inline {
        margin: auto;
    }

    .addPaddingBlock {
        padding-top: 40px;
    }
} */

.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.834);
    z-index: 1;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #3d5af1 transparent #3d5af1 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}