.loader {
    display: inline-block;
    width: 1em;
    height: 1em;
    color: inherit;
    vertical-align: middle;
    pointer-events: none;
}

.loader {
    border-radius: 50%;
    box-shadow: 0 1em 0 -0.2em currentcolor;
    position: fixed;
    -webkit-animation: loader-14 0.8s ease-in-out alternate infinite;
    animation: loader-14 0.8s ease-in-out alternate infinite;
    -webkit-animation-delay: 0.32s;
    animation-delay: 0.32s;
    top: 50%;
    left: 50%;
}

.loader:before {
    content: "";
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    box-shadow: inherit;
    -webkit-animation: inherit;
    animation: inherit;
}

.loader:before {
    left: -1em;
    -webkit-animation-delay: 0.48s;
    animation-delay: 0.48s;
}

.loader:after {
    content: "";
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    box-shadow: inherit;
    -webkit-animation: inherit;
    animation: inherit;
}

.loader:after {
    right: -1em;
    -webkit-animation-delay: 0.16s;
    animation-delay: 0.16s;
}

@keyframes loader-14 {
    0% {
        box-shadow: 0 2em 0 -.2em currentcolor;
    }
    100% {
        box-shadow: 0 1em 0 -.2em currentcolor;
    }
}