/* --------------FAQ------------------- */

.accordion-flush .accordion-item:last-child {
    height: 100%;
}

.FaqBlockContainer {
    /* margin: 0px 8px; */
    padding-bottom: 80px 0px;
}

.FaqHeadding {
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
    color: #26331fe0;
    text-align: center;
}

.FaqBlockContainer .accordion-flush .accordion-item .accordion-button {
    border-radius: 6px;
    border: none;
}

.FaqBlockContainer .accordion-flush .accordion-item {
    border: 1px solid #191C1A1f ;
    border-radius: 6px;
    margin: 0px auto 8px;
    font-size: 20px;
    line-height: 24px;
}

.FaqBlockContainer .accordion-button {
    padding: 0px 0px;
}

.FaqBlockContainer .accordion {
    padding-top: 60px;
}

.FaqBoxHeadding {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;

    margin-bottom: 0px;
}

.accordion-button:not(.collapsed) {
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    color: #85BB65;
}

.FaqBlockContainer .accordion-button:not(.collapsed)::after {
    /* background-image: url(assets/images/FAQsarrow1.svg); */
    transform: rotate(0deg);
}

.FaqBlockContainer .accordion-button::after {
    /* background-image: url(assets/images/FAQs\ arrow.svg); */
    transform: rotate(-90deg);
}

.FaqBlockContainer .accordion-button:focus {
    z-index: 3;
    border: none;
    outline: none;
    box-shadow: none;
}

.FaqBlockContainer .accordion-button:focus-within {
    border: none;
    box-shadow: none;
    border-radius: 20px;
}

.FaqBlockContainer .accordion-button:not(.collapsed) {
    background: #ffffff;
    border: 1px solid rgba(62, 54, 159, 0.12);
}

.FaqBlockContainer .accordion-button {
    background: #ffffff;
    border: 1px solid rgba(62, 54, 159, 0.12);
    box-shadow: 0px 3px 30px rgba(38, 33, 98, 0);
    border-radius: 20px;
}

.FaqBlockContainer .accordion-flush .accordion-item:hover {
    border-color: #191C1A ;
}



.FaqBlockContainer .accordion-flush .accordion-item:focus-within {
    border-color: #191C1A ;
}

.FaqBlockContainer .accordion-body {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    padding: 8px 72px 20px 8px;
    color: #191C1A99;
}

.FaqBlockContainer .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: calc(1.25rem - 1px);
    border-bottom-left-radius: calc(1.25rem - 1px);
}

.FaqPadding {
    padding-top: 40px;
    padding-bottom: 120px;
}
.FaqBoxHeadding {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0px;
}
.accordion-item {
    max-width: 620px;
    margin: 0px auto;
    border-radius: 6px;
    padding: 16px 20px;
}

@media only screen and (max-width: 991px) {
    .FaqBlockContainer .accordion-button {
        padding: 0px 0px;
    }

    /* faq */

    .FaqBoxHeadding {
        font-size: 16px;
        line-height: 19px;
    }

    .FaqBlockContainer .accordion {
        padding-top: 40px;
        padding-bottom: 0px;
    }

    .FaqBlockContainer .accordion-flush .accordion-item {
        margin: 0px 10px 12px;
        height: auto;
    }
}

@media only screen and (max-width: 767px) {

    /* faq */
    .FaqHeadding {
        font-size: 1.6rem;
    }

    .FaqBoxHeadding {
        font-size: 16px;
        line-height: 19px;
    }

    .accordion-body {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
    }


    .FaqBlockContainer .accordion-flush .accordion-item:hover {
        border-color: #191C1A1f !important;
    }

    .FaqBlockContainer .accordion {
        padding-top: 0px;
        /* padding-bottom: 80px; */
    }

    .FaqHeadding {
        font-size: 32px;
        font-weight: 700;
        line-height: 39px;
    }

    .FaqPadding {
        padding-top: 20px;
        padding-bottom: 78px;
    }

    .FaqBlockContainer .accordion-button {
        box-shadow: 0px 3px 30px rgb(38 33 98 / 0%);
    }

    .accordion-button {
        padding: 10px 20px;
    }

    .FaqBlockContainer .accordion-flush .accordion-item {
        margin: 12px 0px;
        padding: 0px 0px;
        height: auto;
        border-radius: 6px;
        border: 0.5px solid #191C1A14 !important;
    }

    .FaqBlockContainer .accordion-button {
        padding: 0px;
    }

    .accordion-button::after {
        background-size: 1rem;
    }

    .accordion-body {
        padding: 2px 20px;
    }

    .FaqBlockContainer .accordion-body {
        font-weight: 400 !important;
        color: #26331fe0;
    }

    .accordion-body p {
        margin-bottom: 5px !important;
    }

    .addPaddingBlock {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .FaqBlockContainer .accordion-body {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding: 0px 20px 10px 20px;
        color: #191C1A99;
    }

    .FaqBlockContainer .accordion-button {
        padding: 12px 16px;
    }

    .accordion-button {
        box-shadow: 0px 3px 30px rgb(38 33 98 / 0%);
    }

    .accordion-button:not(.collapsed) {
        box-shadow: 0px 3px 30px rgb(38 33 98 / 0%);
    }

    .paddingfaqblock {
        padding: 80px 8px;
    }
}

/* 7thBlock Faq
.bolck_bg {
    background: #FAFAFF;
}
.FaqBlockContainer {
    margin: 0px 8px;
}

.FaqHeadding {
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
    color: #1b1b1e;
    text-align: center;
}

.FaqBlockContainer .accordion-flush .accordion-item .accordion-button {
    border-radius: 8px;
    border: none;
}

.FaqBlockContainer .accordion-flush .accordion-item {
    border: 1px solid #1b1b1e1f !important;
    border-radius: 8px;
    margin: 20px 0px;
}

.FaqBlockContainer .accordion-button {
    padding: 20px 32px;
}

.FaqBlockContainer .accordion {
    padding-top: 40px;
    padding-bottom: 80px;
}

.FaqBoxHeadding {
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 0px;
}

.accordion-button:not(.collapsed) {
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    color: #4D45D4;
}

.FaqBlockContainer .accordion-button:not(.collapsed)::after {
    background-image: url(static/assets/images/FAQsarrow1.svg);
    transform: rotate(0deg);
}

.FaqBlockContainer .accordion-button::after {
    background-image: url(static/assets/images/FAQs\ arrow.svg);
    transform: rotate(-90deg);
}

.FaqBlockContainer .accordion-button:focus {
    z-index: 3;
    border: none;
    outline: none;
    box-shadow: none;
}

.FaqBlockContainer .accordion-button:focus-within {
    border: none;
    box-shadow: none;
    border-radius: 20px;
}

.FaqBlockContainer .accordion-button:not(.collapsed) {
    background: #ffffff;
    border: 1px solid rgba(62, 54, 159, 0.12);
}

.FaqBlockContainer .accordion-button {
    background: #ffffff;
    border: 1px solid rgba(62, 54, 159, 0.12);
    box-shadow: 0px 3px 30px rgba(38, 33, 98, 0);
    border-radius: 20px;
}

.FaqBlockContainer .accordion-flush .accordion-item:hover {
    border-color: #1b1b1e !important;
}

.FaqBlockContainer .accordion-flush .accordion-item:focus-within {
    border-color: #1b1b1e !important;
}

.FaqBlockContainer .accordion-body {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    padding: 0px 72px 20px 32px;
    color: #1b1b1e99;
}

.FaqBlockContainer .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: calc(1.25rem - 1px);
    border-bottom-left-radius: calc(1.25rem - 1px);
}

.FaqPadding {
    padding-top: 40px;
    padding-bottom: 120px;
}
@media only screen and (max-width: 991px){
.FaqBoxHeadding {
    font-size: 16px;
}
}
@media only screen and (max-width: 768px){
.FaqBoxHeadding {
    font-size: 20px;
}
}
@media only screen and (max-width: 500px){
.FaqBoxHeadding {
    font-size: 16px;
}}
@media only screen and (max-width: 425px){
.FaqBoxHeadding {
    font-size: 16px;
}
.accordion-button::after {
    width: 1rem;
    height: 1rem;
}
.accordion-button::after {
    background-size: 1rem;
}
}
@media only screen and (max-width: 320px){
.FaqBoxHeadding {
    font-size: 12px;
}
} */