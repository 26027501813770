.field {
    position: relative;
}

label,
input {
    transition: all 0.2s;
    touch-action: manipulation;
}

input {
    border: 0;
    border: 0.5px solid #191c1a;
    font-family: 'Inter';
    -webkit-appearance: none;
    border-radius: 4px;
    padding: 18px 20px;
    cursor: text;
}

input:focus {
    outline: 0;
    border: 2px solid #008836;
    border-radius: 8px;
}

input:placeholder-shown+label {
    cursor: text;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0, 3.1rem) scale(1);
    margin-left: 30px;
    border-radius: 8px;
    /* font-size: 14px; */
    color: #1B1B1EE0;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    position: absolute;
    left: 0;
    bottom: 76px;
}

.field input::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
}

.field input:focus::-webkit-input-placeholder {
    opacity: 0.25;
}

input:not(:placeholder-shown)+label,
input:focus+label {
    transform: translate(0, 0) scale(1);
    cursor: pointer;
    background: linear-gradient(to top, #ffffff 60%, transparent 40%);
    padding: 0px 4px;
    position: absolute;
    left: 12px;
    bottom: 50px;
    margin-left: 12px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
}

input.parsley-error+label,
input:focus {
    color: #1B1B1ECC !important;
}

input:not(:placeholder-shown)+label {
    transform: translate(0, 0) scale(1);
    cursor: pointer;
    /* left: 0; */
}

.lable_spanbg {
    padding: 0px 4px;
}

input:placeholder-shown+label :focus {
    position: absolute;
    left: 12px;
}

input:focus+label {
    color: #008836;
}

input.parsley-error:focus+label {
    color: red !important;
}

.lableSty {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #1b1b1e90;
}

.inputparent_marg {
    padding: 10px
}

@media only screen and (max-width: 767px) {
    .inputparent_marg {
        padding: 8px 0px;
    }
    input:placeholder-shown+label {
        margin-left: 16px;
    }
    input:not(:placeholder-shown)+label,
    input:focus+label {
        transform: translate(0, 0) scale(1);
        cursor: pointer;
        background: linear-gradient(to top, #ffffff 60%, transparent 40%);
        padding: 0px 4px;
        position: absolute;
        left: 0px;
        bottom: 50px;
        margin-left: 12px;
    }
}


/* .field {
  position: relative;
  background-color: rgb(234, 137, 19);
}

label,
input {
  transition: all 0.2s;
  touch-action: manipulation;
}

input {
  border: 0;
  border: 0.5px solid #ffffff;
  font-family: 'Inter';
  -webkit-appearance: none;
  border-radius: 0;
  padding: 18px 20px;
  cursor: text;
}
input:focus {
  outline: 0;

  border: 0.5px solid #4D45D4;
  border-radius: 8px;
}

input:placeholder-shown+label {
  cursor: text;
  max-width: 66.66%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: left bottom;
  transform: translate(0, 3.1rem) scale(1);
  margin-left: 30px;
  border-radius: 8px;
  color: #1B1B1EE0;
  font-weight: 500;
  line-height: 24px;
  position: absolute;
  left: 0;
  bottom: 76px;

}

::-webkit-input-placeholder {
  opacity: 0;
  transition: inherit;
}

input:focus::-webkit-input-placeholder {
  opacity: 0.25;
}

input:not(:placeholder-shown)+label,
input:focus+label {
  transform: translate(0, 0) scale(1);
  cursor: pointer;
  background: linear-gradient(to top, #ffffff 60%, transparent 40%);
  padding: 0px 4px;
  position: absolute;
  left: 0;
  bottom: 52px;
  margin-left: 22px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

input.parsley-error+label,
input:focus {
  color: #1B1B1ECC !important;
}

input:not(:placeholder-shown)+label,
input:focus+label {
  transform: translate(0, 0) scale(1);
  cursor: pointer;
}

.lable_spanbg {
  padding: 0px 4px;
}

input:placeholder-shown+label :focus {
  position: absolute;
  left: 0;
}

input:focus+label {
  color: #4D45D4 !important;
}

input.parsley-error:focus+label {
  color: red !important;
}

.lableSty {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #1b1b1e90;
}

.inputparent_marg {
  padding: 10px
}

@media only screen and (max-width: 425px) {
  input {
    padding: 13px 20px;
  }

  input:placeholder-shown+label {
    bottom: 74px;
  }

  input:not(:placeholder-shown)+label,
  input:focus+label {
    bottom: 52px;
  }

  input:placeholder-shown+label {
    transform: translate(1, 2rem) scale(1);
  }

  .inputparent_marg {
    padding: 8px 0px;
  }

  input:placeholder-shown+label {
    margin-left: 8px 0px;
  }

  input:not(:placeholder-shown)+label,
  input:focus+label {
    margin-left: 12px !important;
  }

  input:placeholder-shown+label {
    margin-left: 20px;
  }

  input:placeholder-shown:focus+label {
    margin-left: 12px;
  }

  .AddPadingCircleArrow {
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (max-width: 375px) {

  input:placeholder-shown+label {
    bottom: 71px;
  }

  input:not(:placeholder-shown)+label,
  input:focus+label {
    bottom: 45px;
  }
} */